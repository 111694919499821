import React, { useState, useEffect } from "react";
import { trackOrderDetails } from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../layout/Loader";

export default function TrackOrder() {
  let location = useLocation();
  const token = useSelector((state) => state.status.token.token);

  const [orderDetailsData, setOrderDetailsData] = useState();
  const [waybill, setWaybill] = useState();
  const [trackOrderData, setTrackOrderData] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.orderDetails) {
      setOrderDetailsData(location.state.orderDetails);
      setWaybill(
        location.state.orderDetails.delhivery_obj &&
          location.state.orderDetails.delhivery_obj.waybill
      );
      //   trackOrderDetails(token, location.state.orderDetails.delhivery_obj.waybill).then((res) => {
      //     console.log(res.data);
      //     setTrackOrderData(res.data)
      //   });
    }
    fetchTrackOrder();

    // eslint-disable-next-line
  }, [location.state.orderDetails]);

  const fetchTrackOrder = () => {
    // setIsLoading(true);
    trackOrderDetails(token, waybill).then((res) => {
      // console.log(res.data.ShipmentData[0]);
      setTrackOrderData(res.data.ShipmentData[0]);
      setIsLoading(false);
    });
  };

  //   console.log(waybill)

  // console.log(orderDetailsData);
  // console.log(trackOrderData);

  return (
    <div>
      {isLoading ? ( // Conditional rendering of loader
        // <div className="spinner-border text-primary" role="status">
        //   <span className="visually-hidden">Loading...</span>
        // </div>
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <div className="ibox">
            <div className="ibox-body m-3  mx-4">
              <div className="d-flex justify-content-center">
                {orderDetailsData && (
                  <div className="text-center">
                    <label className="orderID fw-bolder">
                      Order ID - {orderDetailsData.order.order_id}
                    </label>
                    <br />
                    <label className="orderID" style={{ color: "gray" }}>
                      Status -{" "}
                      {waybill
                        ? orderDetailsData.status === "Canceled"
                          ? "Order Canceled"
                          : "Prepared for Dispatch"
                        : "Order Canceled before Confirmed"}
                    </label>
                  </div>
                )}
              </div>

              {waybill && <div className="track-line mt-5"></div>}

              {waybill && trackOrderData && (
                <div className="d-flex justify-content-center">
                  <div className="order-track">
                    {trackOrderData.Shipment &&
                      trackOrderData.Shipment.Scans.map((item, i) => (
                        <div className="order-track-step" key={i}>
                          <div className="order-track-text">
                            <span className="order-track-text-sub me-4">
                              {item.ScanDetail.ScanDateTime.split("T")[0]}
                            </span>
                            <br />
                            <span className="order-track-text-sub me-4 float-right">
                              {item.ScanDetail.ScanDateTime.split("T")[1]}
                            </span>
                          </div>
                          <div className="order-track-status">
                            <span className="order-track-status-dot"></span>
                            <span className="order-track-status-line"></span>
                          </div>
                          <div className="order-track-text">
                            <span className="order-track-text-sub">
                              {item.ScanDetail.Instructions}
                            </span>
                            <p className="order-track-text-stat">
                              {item.ScanDetail.ScannedLocation.replaceAll(
                                "_",
                                " "
                              )}
                            </p>
                          </div>
                        </div>
                      ))}

                    <div className="order-track-step">
                      <div className="order-track-text">
                        <span className="order-track-text-sub me-4"></span>
                        <br />
                        <span className="order-track-text-sub me-4 float-right"></span>
                      </div>
                      <div className="order-track-status">
                        <span className="order-track-status-dot"></span>
                        <span className="order-track-status-line"></span>
                      </div>
                      <div className="order-track-text">
                        <span className="order-track-text-sub"></span>
                        <p className="order-track-text-stat"></p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
