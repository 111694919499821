import React, { useEffect, useState } from "react";
import {
  getProductsByColorSize,
  updateProductSizeColor,
} from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { hover } from "@testing-library/user-event/dist/hover";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../layout/Loader";

export default function AddonProduct() {
  let navigate = useNavigate();
  let location = useLocation();
  const token = useSelector((state) => state.status.token.token);
  // const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [productDetails, setProductDetails] = useState([]);
  const [productColorSize, setProductColorSize] = useState();
  const [multyImage, setMultyImage] = useState();
  const [isButtonActive, setIsButtonActive] = useState(false); // Added state for button
  const [currentIndex, setCurrentIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [updateProductColorSize, setUpdateProductColorSize] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // const goToPreviousSlide = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  // };

  // const goToNextSlide = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  // };

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const fetchAllProductsByColorSize = () => {
    // setIsLoading(true);
    getProductsByColorSize(token, productDetails.product_id).then((res) => {
      setProductColorSize(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.productDetails) {
      // setIsLoading(true);
      setProductDetails(location.state.productDetails);
      getProductsByColorSize(
        token,
        location.state.productDetails.product_id
      ).then((res) => {
        setProductColorSize(res.data);
        // console.log(res.data);
        setIsLoading(false);
      });
    }

    const totalSlidesCount =
      multyImage && multyImage.image_url.split("@").slice(0, -1).length;
    setTotalSlides(totalSlidesCount);
    // const interval = setInterval(() => {
    //   setIndex((prevIndex) => (prevIndex + 1) % totalSlidesCount);
    // }, 5000);
    // return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [multyImage]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleMultyImageClick = (item) => {
    setIndex(0);
    // setCurrentIndex(0);
    setMultyImage(item);
    // console.log(item);
  };

  const handleEdit = (item) => {
    // console.log(item);
    // item.size = item.size.name;
    setUpdateProductColorSize(item);
    clearErrors();
  };

  // console.log(updateProductColorSize);

  const errorMessages = {
    quantity: {
      message: "Enter Quantity",
      pattern: {
        value: /^\d*$/,
        message: "Quantity must contain only digits",
      },
      validate: (value) =>
        parseInt(value) >= 0 || "Quantity should be valid",
    },

    size: {
      message: "Enter Product Size",
      // pattern: {
      //   value: /^(XXXS|XXS|XS|S|M|L|XL|XXL|XXXL|24|26|28|30|32|34|36|38)$/,
      //   message: "Please enter valid size",
      // },
      // validate: (value) => (value ? "" : "Enter Product Size"),
    },

    price: {
      message: "Enter Price",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Please Enter Valid Price",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Value must be greater than 0",
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the value is empty
    if (!value) {
      setError(name, { type: "manual", message: errorMessages[name].message });
    } else if (errorMessages[name].pattern) {
      const pattern = errorMessages[name].pattern.value;
      if (!pattern.test(value)) {
        setError(name, {
          type: "manual",
          message: errorMessages[name].pattern.message,
        });
      } else if (errorMessages[name].validate) {
        const validate = errorMessages[name].validate;
        const validationError = validate(value);
        if (validationError) {
          setError(name, { type: "manual", message: validationError });
        } else {
          clearErrors(name);
        }
      }
    } else {
      clearErrors(name);
    }

    setUpdateProductColorSize({
      ...updateProductColorSize,
      [name]: value,
    });
  };

  const updateData = () => {
    let size = updateProductColorSize.size;
    if (typeof size === "object" && size !== null) {
      // Size is an object
      // console.log("Size is an object:", size);
      size = size.name;
    }

    const data = {
      color_id: updateProductColorSize.color_id,
      size: size.toString().toUpperCase(),
      price: updateProductColorSize.price,
      quantity: updateProductColorSize.quantity,
    };
    // data.size = item.size;
    // console.log(data);

    updateProductSizeColor(token, data).then((res) => {
      // console.log(res.data);
      toast.success("Product Data Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeUpdateModal").click();
      fetchAllProductsByColorSize();
    });
  };

  // console.log(productColorSize);
  // console.log(productDetails);

  // Function to toggle button's active state
  const toggleButton = () => {
    // reset1({ category_for: "", subcategoryName1: "", uploadImage: true });
    // setUploadImage(null);
    setIsButtonActive(!isButtonActive);

    navigate("/clothing/add-addon-product", {
      state: {
        productColorSize: productColorSize,
        product_id: productDetails.product_id,
      },
    });
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              {/* <Link to="/clothing/add-addon-product"> */}
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                // data-toggle="modal"
                // data-target="#addSubCategoryModal"
              >
                <i className="fa fa-plus"></i>
              </div>
              {/* </Link> */}

              {/* <!-- Edit Modal --> */}

              <div className="container ">
                <div
                  className="modal fade"
                  id="editColorSizeModal"
                  role="dialog"
                >
                  <div className={`modal-dialog`} id="categoryModal">
                    {/* <!-- Edit Modal content--> */}
                    <form
                      id="editForm"
                      data-form-label="update"
                      onSubmit={handleSubmitUpdate(updateData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            UPDATE SIZE & PRICE
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeUpdateModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}
                          {updateProductColorSize && (
                            <div className="update-color-size">
                              <div>
                                <label>
                                  Size <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register("size", {
                                    required: "Please Enter Size",
                                  })}
                                  value={updateProductColorSize.size.name}
                                  onChange={handleInputChange}
                                />
                                {errors.size && (
                                  <p className="text-danger">
                                    {errors.size.message}
                                  </p>
                                )}
                              </div>
                              <div>
                                <label>
                                  Price <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register("price", {
                                    required: "Please Enter Price",
                                  })}
                                  value={updateProductColorSize.price}
                                  onChange={handleInputChange}
                                />
                                {errors.price && (
                                  <p className="text-danger">
                                    {errors.price.message}
                                  </p>
                                )}
                              </div>
                              <div>
                                <label>
                                  Total Available QTY{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register("quantity", {
                                    required: "Please Enter Quantity",
                                  })}
                                  value={updateProductColorSize.quantity}
                                  onChange={handleInputChange}
                                />
                                {errors.quantity && (
                                  <p className="text-danger">
                                    {errors.quantity.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-default">
                            UPDATE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Image Modal */}

              <div className="container">
                <div
                  className="modal fade"
                  id="multipleImageModal"
                  role="dialog"
                >
                    <div className={`modal-dialog multiple-Image`}>
                      <div className="modal-content">
                        <div className="modal-body multipleImageBody ">
                          <Carousel
                            key={index}
                            activeIndex={index}
                            onSelect={handleSelect}
                            interval={null}
                            pause={hover}
                          >
                            {multyImage &&
                              multyImage.image_url
                                .split("@")
                                .slice(0, -1)
                                .map((imageUrl, idx) => (
                                  <Carousel.Item key={idx}>
                                    <img
                                      className="d-block w-100 adjust-image-height"
                                      src={imageUrl}
                                      alt={`Slide ${idx}`}
                                    />
                                  </Carousel.Item>
                                ))}
                          </Carousel>
                          {/* <div className="carousel-dots">
                  {Array.from({ length: totalSlides }, (_, idx) => (
                    <span
                      key={idx}
                      className={`dot ${idx === index ? 'active' : ''}`}
                      onClick={() => setIndex(idx)}
                    ></span>
                  ))}
                </div> */}

                          {/* <button
                  className="carousel-control-prev"
                  type="button"
                  onClick={goToPreviousSlide}
                  disabled={index === 0}
                >
                  <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  onClick={goToNextSlide}
                  disabled={index === totalSlides - 1}
                >
                  <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button> */}
                        </div>
                      </div>
                    </div>
                  
                </div>
              </div>

              <h4 className="modal-title product-details fw-bold fs-3">
                {(productDetails && productDetails.product_name) ||
                  productDetails.initialAddProduct.product_name}
                {/* -{" "} */}
                {/* {productDetails && productDetails.master_category || productDetails.initialAddProduct.master_category} */}
              </h4>

              <table
                className="table table-striped table-bordered table-hover"
                // id="example-table"
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th>Price</th>
                    <th>Available Qty</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {productColorSize &&
                    productColorSize.color_obj_dict.map((item, i) => (
                      <tr
                        key={`${item.color_id}_${item.size.id}`}
                        className="tr"
                      >
                        <td>{i + 1}</td>
                        <td>
                          <img
                            src={item.image_url.split("@")[0]}
                            alt="SizeColor"
                            className="productImage"
                            onClick={() => handleMultyImageClick(item)}
                            data-toggle="modal"
                            data-target="#multipleImageModal"
                            style={{ cursor: "pointer" }}
                          />
                          <img
                            className="multipleImage ms-5"
                            src="/assets/img/gallery.png"
                            alt="..."
                            id="multyImageCurrentIndex"
                            onClick={() => handleMultyImageClick(item)}
                            data-toggle="modal"
                            data-target="#multipleImageModal"
                          />
                        </td>
                        <td>{item.color.split(",")[0]}</td>
                        <td>{item.size.name}</td>
                        <td>
                          <i className="fa fa-inr"></i>
                          {item.price}
                        </td>
                        <td>{item.quantity}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => handleEdit(item)}
                            data-toggle="modal"
                            data-target="#editColorSizeModal"
                          >
                            <i className="fa fa-pencil popup me-3"></i>
                          </Link>
                          {/* <Link
                        to="#"
                        // onClick={() => handleDelete(item)}
                        // data-toggle="modal"
                        // data-target="#deleteCategoryModal"
                      >
                        <i className="fa fa-trash ms-3"></i>
                      </Link> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
