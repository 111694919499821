import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addCategoryData,
  deleteCategoryData,
  getMasterCategory,
  updateCategoryData,
  addOpenHours,
  getSubCategory,
} from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loader from "../layout/Loader";

export default function Category() {
  const [isButtonActive, setIsButtonActive] = useState(false); // Added state for button
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [masterCategory, setMasterCategory] = useState();
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // console.log(masterCategory);

  // Function to toggle button's active state
  const toggleButton = () => {
    reset1({ categoryName1: "", uploadImage: true });
    setUploadImage(null);
    setIsButtonActive(!isButtonActive);
  };

  const fetchCategory = () => {
    getMasterCategory(token, restaurantId).then((res) => {
      // console.log(res.data);
      setMasterCategory(res.data);
      setIsLoading(false);
    });
    getSubCategory(token, restaurantId).then((res) => {
      setSubCategoryData(res.data);
      // console.log(res.data);
      setIsLoading(false);
    });
  };

  // console.log(masterCategory, subCategoryData);

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
    clearErrors,
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmitAdd,
    reset: reset1,
    setError: setError1,
    formState: { errors: errors1 },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const [editedItem, setEditedItem] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [editedImage, setEditedImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);

  const handleEditChangeSubcategoryName = (e) => {
    setEditedCategoryName(e.target.value);
  };

  const handleEdit = (item) => {
    reset2({ confirmCategory: "" });
    setEditedItem(item);
    setEditedCategoryName(item.master_category_name);
    setEditedImage(item.image_url);
    clearErrors()
  };

  const updateImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setEditedImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImage").src = e.target.result;
        // Set the editedImage state with the new image data
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);

      input.value = "";
    }
  };

  const handleUpdateImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputUpdate").click();
  };

  const updateData = () => {
    let data;
    if (typeof editedImage === "string") {
      data = {
        name: editedCategoryName,
        master_category_id: editedItem.master_category_id,
      };
    } else {
      data = {
        name: editedCategoryName,
        image: editedImage,
        master_category_id: editedItem.master_category_id,
      };
    }

    // console.log(data);
    updateCategoryData(token, data).then((res) => {
      // console.log(res);
      toast.success("Category Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeUpdateModal").click();
      fetchCategory();
    });
  };

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setUploadImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImageAdd").src = e.target.result;

        setError1("uploadImage", { type: "manual", message: "" });
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  const addData = (item) => {
    // console.log(item);
    // console.log(selectedMasterCategory);

    const data = {
      name: item.categoryName1,
      restaurant_id: restaurantId,
      image: uploadImage,
      status: "True",
    };

    // console.log(data)

    addCategoryData(token, data).then((res) => {
      // console.log(res.data);

      const data = {
        open_hours: `{"monday":"00:01@23:59@true",
                      "tuesday":"00:01@23:59@true",
                      "wednesday":"00:01@23:59@true",
                      "thursday":"00:01@23:59@true",
                      "friday":"00:01@23:59@true",
                      "saturday":"00:01@23:59@true",
                      "sunday":"00:01@23:59@true"}`,
        master_category: res.data.master_category_id,
      };

      // console.log(data);

      addOpenHours(token, data).then((res) => {
        // console.log(res);
      });

      toast.success("Category Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmCategory: "" });

      document.getElementById("closeAddModal").click();
      fetchCategory();
    });
    reset1({ categoryName1: "", uploadImage: true });
    setUploadImage(null);
    // fetchCategory();
  };

  const handleDelete = (item) => {
    // console.log(item);
    reset2({ confirmCategory: "" });
    setDeletedItem(item);
  };

  const deleteData = () => {
    // console.log(data)
    // console.log(deletedItem);
    deleteCategoryData(token, deletedItem.master_category_id).then((res) => {
      // console.log(res);
      toast.error("Category Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmCategory: "" });
      document.getElementById("closeDeleteModal").click();
      fetchCategory();
    });
  };

  const handleStatusChange = (item) => {
    // console.log(item);
    const data = {
      status: !item.status,
      master_category_id: item.master_category_id,
    };

    // console.log(data);
    updateCategoryData(token, data).then((res) => {
      fetchCategory();
      // console.log(res.data);
    });
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                data-toggle="modal"
                data-target="#addCategoryModal"
              >
                <i className="fa fa-plus"></i>
              </div>

              {/* <!-- Edit Modal --> */}

              <div className="container ">
                <div
                  className="modal fade"
                  id="editCategoryModal"
                  role="dialog"
                >
                  <div className={`modal-dialog`} id="categoryModal">
                    {/* <!-- Edit Modal content--> */}
                    <form
                      id="editForm"
                      data-form-label="update"
                      onSubmit={handleSubmitUpdate(updateData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            UPDATE CATEGORY
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeUpdateModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}
                          {editedItem && (
                            <div>
                              <div>
                                <label>
                                  Category <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  value={editedCategoryName}
                                  {...register("categoryName", {
                                    required: "Please Enter Category Name",
                                  })}
                                  onChange={handleEditChangeSubcategoryName}
                                />
                                {errors.categoryName && (
                                  <p className="text-danger position-absolute">
                                    {errors.categoryName.message}
                                  </p>
                                )}
                              </div>

                              <div>
                                <label>
                                  Category Image{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                {/* Hidden file input */}
                                <input
                                  type="file"
                                  id="imageInputUpdate"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  onChange={updateImage}
                                />

                                {/* Displayed image */}

                                <img
                                  src={editedImage}
                                  id="displayedImage"
                                  alt={editedItem.image_url}
                                  onClick={() => handleUpdateImageClick()}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-default">
                            UPDATE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* <!-- Add Modal --> */}

              <div className="container ">
                <div className="modal fade" id="addCategoryModal" role="dialog">
                  <div className="modal-dialog">
                    {/* <!-- Add Modal content--> */}
                    <form
                      id="addForm"
                      data-form-label="add"
                      onSubmit={handleSubmitAdd(addData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            ADD SUBCATEGORY
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeAddModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}

                          <div>
                            <div>
                              <label className="mt-3">
                                Category <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                {...register1("categoryName1", {
                                  required: "Please Enter Category Name",
                                })}
                              />
                              {errors1.categoryName1 && (
                                <p className="text-danger position-absolute">
                                  {errors1.categoryName1.message}
                                </p>
                              )}
                            </div>

                            <div>
                              <label>
                                Category Image{" "}
                                <i className="fa fa-asterisk"></i>
                              </label>

                              {/* Hidden file input */}
                              <input
                                type="file"
                                id="imageInputAdd"
                                accept="image/*"
                                defaultValue={uploadImage}
                                {...register1("uploadImage", {
                                  required: "Please Upload Image",
                                })}
                                style={{ display: "none" }}
                                onChange={addImage}
                              />

                              {/* Displayed image */}

                              <img
                                src={
                                  uploadImage ||
                                  "/assets/img/custom/uploadImage.png"
                                }
                                id="displayedImageAdd"
                                alt="Upload"
                                onClick={() => handleAddImageClick()}
                              />

                              {errors1.uploadImage && (
                                <p className="text-danger position-absolute">
                                  {errors1.uploadImage.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-default">
                            ADD
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* <!-- Delete Modal --> */}
              <div className="container ">
                <div
                  className="modal fade"
                  id="deleteCategoryModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Delete Modal content--> */}
                    <form
                      id="deleteForm"
                      data-form-label="delete"
                      onSubmit={handleSubmitDelete(deleteData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">WARNING</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeDeleteModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-5">
                            After delete this 'Category', All data related to
                            this 'Category' will be deleted.
                          </p>
                          <p className="fs-5 fw-bold">
                            You still want to delete this 'Category'!
                          </p>
                          <p className="fw-light">
                            Confirm your selected 'Category' to Delete
                          </p>
                          {deletedItem && (
                            <div>
                              <input
                                hidden
                                value={deletedItem.master_category_name}
                                {...register2("categoryDelete")}
                              />
                              <input
                                {...register2("confirmCategory", {
                                  required: "Please Enter Category",
                                  validate: (value) => {
                                    if (watch("categoryDelete") !== value) {
                                      return "Sorry! Your Selected 'Category' do not Match";
                                    }
                                  },
                                })}
                              />
                              {errors2.confirmCategory && (
                                <p className="text-danger position-absolute">
                                  {errors2.confirmCategory.message}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-default">
                            DELETE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between my-3">
                <table
                  className="table table-striped table-bordered table-hover"
                  cellSpacing="0"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Category</th>
                      <th>Image</th>
                      {/* <th>Availability</th> */}
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {masterCategory &&
                      masterCategory.map((item, i) => (
                        <tr className="draggingItem tr" key={i}>
                          <td>{i + 1}</td>
                          <td>{item.master_category_name}</td>
                          <td>
                            <img src={item.image_url} alt={item.category} />
                          </td>
                          {/* <td>{item.available_time}</td> */}
                          <td>
                            <Link
                              to="#"
                              onClick={() => handleEdit(item)}
                              data-toggle="modal"
                              data-target="#editCategoryModal"
                            >
                              <i className="fa fa-pencil popup me-3"></i>
                            </Link>
                            {subCategoryData.filter(
                              (subCategory) =>
                                subCategory.master_category ===
                                item.master_category_id
                            ).length ? (
                              <Link
                                to="#"
                                // onClick={() => handleDelete(item)}
                                // data-toggle="modal"
                                // data-target="#deleteCategoryModal"
                              >
                                <i
                                  className="fa fa-trash disabled-trash ms-3"
                                  aria-disabled
                                ></i>
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                onClick={() => handleDelete(item)}
                                data-toggle="modal"
                                data-target="#deleteCategoryModal"
                              >
                                <i className="fa fa-trash popup ms-3"></i>
                              </Link>
                            )}
                          </td>
                          <td>
                            <div className="form-check product-check form-switch d-flex justify-content-center fs-4">
                              <input
                                className="form-check-input product-input"
                                type="checkbox"
                                id={`flexSwitchCheckChecked_${item.master_category_id}`}
                                value={item.status}
                                checked={item.status}
                                onChange={() => handleStatusChange(item)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
