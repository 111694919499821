export const generateTokenAction = (token) => ({
    type: 'GENERATE_TOKEN',
    payload: { token },
  });

export const restaurantAdminAction = (data) => ({
    type: 'RESTAURANT_ADMIN',
    payload: data,
});

export const logoutSession = () => ({
  type: 'LOGOUT_SESSION'
})

export const clearToken = () => ({
  type: 'CLEAR_TOKEN'
})