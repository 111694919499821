import React, { useEffect, useState } from "react";
import {
  addProductSpecification,
  deleteProductSpecification,
  getProductSpecification,
  getSpecificationLabel,
} from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../layout/Loader";

export default function Specification() {
  let location = useLocation();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [productSpecification, setProductSpecification] = useState();
  const [isButtonActive, setIsButtonActive] = useState(false); // Added state for button
  const [productDetails, setProductDetails] = useState(null);
  const [specificationLabel, setSpecificationLabel] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const {
    register,
    handleSubmit: handleSubmitAdd,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const fetchSpecification = () => {
    // setIsLoading(true);
    getProductSpecification(token, productDetails?.product_id).then((res) => {
      setProductSpecification(res.data);
      // console.log(res.data)
    });

    getSpecificationLabel(token, "Clothing").then((res) => {
      setSpecificationLabel(res.data);
      // console.log(res.data)
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.productDetails) {
      // setIsLoading(true);
      setProductDetails(location.state.productDetails);
      getProductSpecification(
        token,
        location.state.productDetails.product_id
      ).then((res) => {
        setProductSpecification(res.data);
        // console.log(res.data);
      });
    }

    getSpecificationLabel(token, "Clothing").then((res) => {
      setSpecificationLabel(res.data);
      // console.log(res.data);
      setIsLoading(false);
    });

    // eslint-disable-next-line
  }, []);

  const handleDelete = (item) => {
    // console.log(item);
    deleteProductSpecification(token, item.specification_id).then((res) => {
      // console.log(res);
      toast.error("Product Specification Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      fetchSpecification();
    });
  };

  // Function to toggle button's active state
  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
    reset();
  };

  const addSpecification = (item) => {
    // console.log(item);

    const keyValueArray = [];
    for (let i = 0; i < Object.keys(item).length / 2; i++) {
      const key = item[`key${i}`];
      const value = item[`value${i}`];
      const addoncontent = "";
      const product_id = productDetails && productDetails.product_id;
      const type = "Specification";
      const extra_data = "";
      const industry_type = "Clothing";
      if (key && value) {
        keyValueArray.push({
          key,
          value,
          addoncontent,
          product_id,
          type,
          extra_data,
          industry_type,
        });
      }
    }

    const data = keyValueArray;

    // console.log(data);

    if (data.length > 0) {
      addProductSpecification(token, data).then((res) => {
        // console.log(res.data);

        toast.success("Specification Added...!", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
        });

        document.getElementById("closeAddModal").click();
        fetchSpecification();
        reset();
      });
    }
  };

  // console.log(productDetails);
  // console.log(productSpecification);
  // console.log(specificationLabel);

  // Filter specification labels based on product's sub_category
  const filteredLabels =
    specificationLabel &&
    specificationLabel.filter((label) => {
      // Split the overview_group string by commas
      const overviewGroups = label.overview_group;
      // Check if any of the substrings match the product sub_category
      return overviewGroups.includes(productDetails?.sub_category.trim());
    });

  // // Extract unique "Care" values from filtered labels
  // const allCareValues = filteredLabels
  //   ? [
  //       ...new Set(
  //         specificationLabel &&
  //           specificationLabel
  //             .filter((label) => label.key === "Care")
  //             .map((label) => label.value)
  //       ),
  //     ]
  //   : [];

  //   console.log(filteredLabels)

  // Initialize uniqueKeyValuePairs with the "Care" key and all possible values
  const uniqueKeyValuePairs = {};
  // { Care: allCareValues };

  // Extract "Weight" and "Dimensions" from filtered labels if overview_group_title is "Specification"
  specificationLabel &&
    specificationLabel.forEach((label) => {
      if (label.overview_group === "Specification") {
        const key = label.key.replace(/['"]/g, "");
        if (!uniqueKeyValuePairs[key]) {
          uniqueKeyValuePairs[key] = [];
        }
        if (!uniqueKeyValuePairs[key].includes(label.value)) {
          uniqueKeyValuePairs[key].push(label.value);
        }
      }
    });

  // Extract unique key-value pairs from filtered labels
  filteredLabels &&
    filteredLabels.forEach((label) => {
      // Remove single or double quotes from keys
      const key = label.key.replace(/['"]/g, "");
      // Check if the key already exists
      if (!uniqueKeyValuePairs[key]) {
        // If the key doesn't exist, initialize it with an empty array
        uniqueKeyValuePairs[key] = [];
      }
      // Add the value to the array for this key if it's not already there
      if (!uniqueKeyValuePairs[key].includes(label.value)) {
        uniqueKeyValuePairs[key].push(label.value);
      }
    });

  // console.log(uniqueKeyValuePairs);

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              {/* <Link to="/clothing/add-addon-product"> */}
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                data-toggle="modal"
                data-target="#addSpecificationModal"
              >
                <i className="fa fa-plus"></i>
              </div>
              {/* </Link> */}

              {/* <!-- Add Modal --> */}

              <div className="container ">
                <div
                  className="modal fade"
                  id="addSpecificationModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Add Modal content--> */}
                    <form
                      id="addForm"
                      data-form-label="add"
                      onSubmit={handleSubmitAdd(addSpecification)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            ADD PRODUCT SPECIFICATION
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeAddModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}

                          <div>
                            <div className="d-flex mb-2">
                              <div className="me-5">
                                <label className="me-3">
                                  Specification Type{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>
                              <div>
                                <label className="ms-5">
                                  Specification Value{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>
                            </div>

                            {uniqueKeyValuePairs &&
                              Object.entries(uniqueKeyValuePairs).map(
                                ([key, values], i) => (
                                  <div key={i} className="d-flex">
                                    <div className="w-50 me-2">
                                      <input
                                        value={key}
                                        {...register(`key${i}`)}
                                      />
                                    </div>
                                    <div className="w-50 ms-2">
                                      {values[0] !== null ? (
                                        <select
                                          className="form-select p-2"
                                          defaultValue=""
                                          {...register(`value${i}`)}
                                          // onChange={(e) => e}
                                        >
                                          <option disabled value="" selected>
                                            Select Specification Value
                                          </option>
                                          {values.map((value, index) => (
                                            <option key={index} value={value}>
                                              {value}
                                            </option>
                                          ))}
                                        </select>
                                      ) : (
                                        <input placeholder="Enter Specification Value" {...register(`value${i}`)} />
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-default"
                            // data-dismiss="modal"
                            // onClick={addData}
                          >
                            ADD
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <h4 className="modal-title product-details fw-bold fs-3">
                {productDetails && productDetails.product_name} -{" "}
                {productDetails && productDetails.master_category}
              </h4>

              <table
                className="table table-striped table-bordered table-hover"
                // id="example-table"
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Specification</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {productSpecification &&
                    productSpecification.map((item, i) => (
                      <tr key={i} className="tr">
                        <td>{i + 1}</td>
                        <td>{item.key}</td>
                        <td>{item.value}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => handleDelete(item)}
                            // data-toggle="modal"
                            // data-target="#deleteSpecification"
                          >
                            <i className="fa fa-trash popup me-3"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
