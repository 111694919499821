import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getRestaurantDetails } from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../layout/Loader";

export default function Support() {
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);

  const [restaurantDetails, setRestaurantDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const fetchRestaurantDetails = () => {
    // setIsLoading(true);
    getRestaurantDetails(token, restaurantId).then((res) => {
      setRestaurantDetails(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchRestaurantDetails();
  }, []);

  // console.log(restaurantDetails);

  const {
    register,
    setError,
    clearErrors,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm();

  // console.log(subCategoryData);

  const updateData = (data) => {
    // console.log(updateRestaurantDetails);

    // console.log(data);
    // updateBusinessDetails(token, data, restaurantId).then((res) => {
    //   console.log(res);
    //   toast.success("Business Details Updated...!", {
    //     autoClose: 1500,
    //     style: {
    //       backgroundColor: "black",
    //       color: "white",
    //     },
    //   });
    //   fetchRestaurantDetails();
    // });
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitUpdate(updateData)}
              >
                <div className="modal-content product-modal product-modal-2">
                  <div className="modal-header d-flex justify-content-between">
                    <h4 className="modal-title fw-bold fs-5">
                      CONTACT DETAILS
                    </h4>
                  </div>
                  <div className="px-5 support">
                    <div className="modal-body d-flex justify-content-center p-5 mt-5">
                      <p className="mt-5 fs-2 fw-bolder">
                        Business interested in partnership, please email us at
                      </p>
                    </div>
                    <div className="modal-body d-flex justify-content-center">
                      <p className="fs-3 policy">hello@instaapp.online</p>
                    </div>
                    <div className="modal-body d-flex justify-content-center">
                      <p className="fs-3 policy">+1-(512) 203-7627</p>
                    </div>
                    <div className="modal-body d-flex justify-content-center">
                      <p className="fs-3 policy">+1-(832) 457-7473</p>
                    </div>
                    <div className="modal-body d-flex justify-content-center p-5 mt-3">
                      <p className="fs-2 fw-bolder">
                        Business interested in partnership, please email us at
                      </p>
                    </div>
                    <div className="modal-body d-flex justify-content-center">
                      <p className="fs-3 policy">support@instaapp.online</p>
                    </div>
                    <div className="modal-body d-flex justify-content-center p-5 mb-5 mt-3">
                      <p className="fs-2 fw-bolder">
                        Connect with us for special deals, announcements and
                        news at{" "}
                        <a href="">
                           <label className="policy fs-3">InstaApp.online</label>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
