import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getPlanDetails,
  getSubscriptionDetails,
  createSubscription,
} from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../layout/Loader";
import useRazorpay from "react-razorpay";
import axios from "axios";

export default function Subscription() {
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);

  const [planDetails, setPlanDetails] = useState([]); // Restaurant Details
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [paymentData, setPaymentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [paymentID, setPaymentId] = useState(null);
  const [Razorpay, RazorpayResponse] = useRazorpay();

  const fetchPlanDetails = () => {
    // setIsLoading(true);
    getPlanDetails(token, restaurantId).then((res) => {
      setPlanDetails(res.data);
      getSubscriptionDetails(token, restaurantId, res.data.plan.plan_id).then(
        (res) => {
          setSubscriptionDetails(res && res.data[0]);
        }
      );
      setIsLoading(false);
    });
  };

  useEffect(() => {
    // const script = document.createElement( "script" );
    // script.src = "https://checkout.razorpay.com/v1/checkout.js";
    // script.async = true;
    // document.body.appendChild(script);

    window.scrollTo(0, 1);
    fetchPlanDetails();
    // if (isLoaded) {
    //   handlePayment();
    // }

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);

  console.log(planDetails);
  // console.log(subscriptionDetails)

  const {
    register,
    setError,
    clearErrors,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm();

  // console.log(subCategoryData);

  const updateData = (data) => {
    // console.log(updateplanDetails);

    // console.log(data);
    // updateBusinessDetails(token, data, restaurantId).then((res) => {
    //   console.log(res);
    toast.success("Request sent for Subscription Cancellation", {
      autoClose: 1500,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
    //   fetchplanDetails();
    // });
  };

  // Razorpay
 

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      // Make a request to your backend service to create a subscription
      await createSubscription(token, restaurantId);
      // If successful, you might want to update UI or show a confirmation message
    } catch (error) {
      console.error("Error creating subscription:", error);
      // Handle any errors, perhaps show an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  
 
 

  const [razorpayInstance, setRazorpayInstance] = useState(null);

 
  const handleSubscriptionCreation = async (res) => {
    try {
      const subscription = await createSubscription(res);
      console.log(subscription);
      // Handle the subscription response
    } catch (error) {
      console.error("Error creating subscription:", error);
      // Handle error
    }
  };
 

  // -------------------------------- 5 -----------------------

  const openRazorpay = async () => {
    try {
      const planId = "plan_NYJfJaLlBEKAMk"; // Replace with your plan ID
      const apiKey = "rzp_test_b4YXoasdFeVMUB"; // Replace with your Razorpay API key
      const apiSecret = "ztv03jixBnjlkluxkudOrqfp"; // Replace with your Razorpay API secret

      const requestData = {
        key: apiKey,
        amount: planDetails.plan.price * 100, // Amount in paisa (e.g., 10000 paisa = ₹100)
        currency: "INR",
        name: "InstaApp",
        description: "Subscription Payment",
        receipt: "receipt#1",
        plan_id: planId,
        customer_notify: 1,
        total_count: 6,
        quantity: 1,
        payment_capture: 1, // Auto capture payment
        image: "/assets/img/favicon.png", // Logo URL
        prefill: {
          name: "Your Name",
          email: "your.email@example.com",
          contact: "1234567890",
        },
        start_at: Math.floor(Date.now() / 1000),
        // notify_url: 'your_notify_url', // Optional: Replace with your webhook endpoint to receive notifications
        notes: {
          key: apiKey,
          subscription_type: "monthly",
        },
        theme: {
          color: "#ff6f40", // Theme color
        },
        handler: async function (res) {
          console.log(res);
          // Handle payment success
          try {
            // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'; // CORS proxy service URL
            const proxyUrl = "https://cors.io/"; // Another CORS proxy service URL

            const razorpayUrl = "https://api.razorpay.com/v1/subscriptions"; // Razorpay API subscriptions endpoint

            const authString = `Basic ${btoa(`${apiKey}:${apiSecret}`)}`; // Encoding API key and secret

            JSON.stringify(requestData);

            const response = await axios.post(`${razorpayUrl}`, requestData, {
              headers: {
                Authorization: authString,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            });

            console.log(response.data);
            // Handle the subscription response here
          } catch (error) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              console.error(error.response.data.error.description);
            } else {
              console.error(
                "An error occurred while creating subscription.",
                error
              );
            }
          }
        },
      };

      const instance = new Razorpay(requestData);
      instance.open();
    } catch (error) {
      console.error("Error:", error);
    }
  };


  //-------------------------------------

  

  

  window.handleSubscriptionResponse = (data) => {
    console.log("Subscription response:", data);
    // Handle the subscription response here
  };


  const [response, setResponse] = useState(null);

  const handleSubscription = async () => {
    // const apiKey = 'YOUR_KEY_ID';
    // const apiSecret = 'YOUR_KEY_SECRET';
    const apiKey = "rzp_test_b4YXoasdFeVMUB"; // Replace with your Razorpay API key
    const apiSecret = "ztv03jixBnjlkluxkudOrqfp"; // Replace with your Razorpay API secret

    const requestBody = {
      // plan_id: 'plan_NYJfJaLlBEKAMk',
      amount: planDetails.plan.price * 100,
      currency: 'INR',
      image: '/assets/img/favicon.png', // Logo URL
      total_count: 6,
      quantity: 1,
      customer_notify: 1,
      // start_at: 1580453311,
      // expire_by: 1580626111,
      handler: async function (res) {
        console.log(res);
      },
      theme: {
        color: '#ff6f40', // Theme color
      },
      prefill: {
              name: 'Your Name',
              email: 'your.email@example.com',
              contact: '1234567890',
            },
      addons: [
        {
          item: {
            name: 'Delivery charges',
            // amount: 30000,
            currency: 'INR'
          }
        }
      ],
      offer_id: 'offer_JHD834hjbxzhd38d',
      notes: {
        notes_key_1: 'Tea, Earl Grey, Hot',
        notes_key_2: 'Tea, Earl Grey… decaf.'
      }
      
    };
    JSON.stringify(requestBody)


    try {
      const response = await fetch('https://api.razorpay.com/v1/subscriptions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(apiKey + ':' + apiSecret)}`
        },
        body: requestBody
      });

      const responseData = await response.json();
      setResponse(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  console.log(response)

  
  const handlePayment = () => {
    const options = {
      key: 'rzp_test_b4YXoasdFeVMUB',
      key_id: 'rzp_test_b4YXoasdFeVMUB',
      key_secret: 'ztv03jixBnjlkluxkudOrqfp',
      // key: 'rzp_test_b4YXoasdFeVMUB', // Replace with your Razorpay key ID (rzp_test_b4YXoasdFeVMUB) (rzp_live_HFx9Cp8gyRM8eb)
      amount: 1 * 100, // Amount in paisa (e.g., 10000 paisa = ₹100)
      currency: 'INR',
      name: 'InstaApp',
      description: 'Subscription Payment',
      image: '/assets/img/favicon.png', // Logo URL
      // order_id: 'order_12345', // Replace with dynamically generated order ID from your backend
      prefill: {
        name: 'Your Name',
        email: 'your.email@example.com',
        contact: '1234567890',
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#ff6f40', // Theme color
      },
      handler: function (res) {
        console.log(res);
        setPaymentData(res)
        // Handle payment success
        // You can call an API to update payment status or perform any other action
      },
    };

    var instance = new Razorpay(options) // { key_id: 'rzp_test_b4YXoasdFeVMUB', key_secret: 'ztv03jixBnjlkluxkudOrqfp' }


    instance.orders.create({
      amount: 100,
      currency: "INR",
      receipt: "receipt#1",
      notes: {
        key1: "value3",
        key2: "value2"
      }
    })

    instance.open()


    // const rzp = new Razorpay(options);
    // rzp.open();
  };

 


  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                // onSubmit={handleSubmitUpdate(updateData)}
              >
                <div className="modal-content product-modal product-modal-2 ">
                  <div className="modal-header d-flex justify-content-between">
                    <h4 className="modal-title fw-bold fs-5">
                      SUBSCRIPTION DETAILS
                    </h4>
                    {/* <button
                  hidden={true}
                  type="button"
                  className={`btn-secondary-post-code btn-post-code`}
                  data-toggle="modal"
                  data-target="#addProductDetailsModal"
                  // onClick={handleConfirm}
                >
                  ADD POST CODE
                </button> */}
                  </div>
                  <div className="modal-body mt-5">
                    {/* <p>Some text in the modal.</p> */}
                    {planDetails && (
                      <div className="d-flex">
                        <div className="w-50">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <label>
                                        Business Name{" "}
                                        <i className="fa fa-asterisk"></i>
                                      </label>
                                    </div>

                                    <input
                                      className="input"
                                      value={planDetails?.name}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <label>
                                        Plan <i className="fa fa-asterisk"></i>
                                      </label>
                                    </div>

                                    <input
                                      className="input"
                                      value={planDetails.plan?.plan_name}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <label>
                                        Status{" "}
                                        <i className="fa fa-asterisk"></i>
                                      </label>
                                    </div>

                                    <input
                                      className="input"
                                      value={planDetails?.status}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="vertical-line"></div> */}
                        <div className="productDetails py-4 px-3 h-100 me-4 mt-2">
                          <p className="fs-3 fw-bolder p-2">
                            Subscription Amount :
                          </p>
                          {subscriptionDetails && subscriptionDetails.id ? (
                            <label className="fs-5 mt-5 ms-2">
                              Thank You for paying the amount. Now, your
                              subscription will renew automatically every month
                              by recurring payment.
                            </label>
                          ) : (
                            <label className="fs-5 mt-5 ms-2">
                              Payable Amount for per Month:{" "}
                              <i className="fa fa-inr"></i>
                              {planDetails.plan.price}/-
                              <br />
                              Thank you for choosing our services!
                            </label>
                          )}
                          <div className="form-check ms-2 mt-4">
                            <input
                              className="form-check-input subscription-check-box"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              {...register("isAgreed", {
                                required:
                                  "You must agree to our terms and conditions.",
                              })}
                              checked
                            />

                            <label
                              className="form-check-label pointer"
                              for="flexCheckDefault"
                            >
                              <a
                                href="https://instaapp.online/privacy-policy"
                                className="policy"
                              >
                                I Agree to the InstaApp Privacy Policy
                              </a>
                            </label>
                          </div>
                          {errors.isAgreed && (
                            <p className="text-primary position-absolute checkbox-p">
                              {errors.isAgreed.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    {subscriptionDetails && subscriptionDetails.id ? (
                      <button
                        type="button"
                        className="btn btn-default"
                        // data-dismiss="modal"
                        onClick={() => updateData()}
                      >
                        REQUEST FOR CANCELLATION
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-default"
                        // data-dismiss="modal"
                        onClick={() => handlePayment()}
                        // onClick={() => handleSubscribe()}
                        // onClick={() => openRazorpay()}
                        // onClick={() => handleSubscription()}
                      >
                        PAY NOW
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
