import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createCancelOrder,
  createDeliveryOrder,
  getPaymentDetails,
  getProductDetails,
  markProductUnavailable,
} from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../layout/Loader";
// import TimePicker from "@ashwinthomas/react-time-picker-dropdown";

export default function ProductDetails() {
  let location = useLocation();
  let navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [productDetailsData, setProductDetailsData] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [confirmOrderDetails, setConfirmOrderDetails] = useState();
  const [hideAll, setHideAll] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [orderID, setOrderID] = useState();
  const [disabled, setDisabled] = useState(false);
  const [value, onChange] = useState("10:00");
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.orderDetails) {
      setHideAll(location.state.hideAll);
      setHidden(location.state.hidden);
      setDisabled(location.state.disabled);
      setOrderID(location.state.orderDetails.order.order_id);
      getProductDetails(token, location.state.orderDetails.order.order_id).then(
        (res) => {
          setProductDetailsData(res.data);
          setOrderDetailsData(location.state.orderDetails);
          setIsLoading(false);
        }
      );
      getPaymentDetails(token, location.state.orderDetails.order.order_id).then(
        (res) => {
          console.log(res.data);
          setPaymentDetails(res.data);
          setConfirmOrderDetails(
            JSON.parse(res.data.complete_shipping_address)
          );
          setIsLoading(false);
        }
      );
    }
  }, [
    location.state.orderDetails,
    location.state.hideAll,
    location.state.hidden,
    location.state.disabled,
    token,
  ]);

  const fetchOrderDetails = () => {
    // setIsLoading(true);
    getProductDetails(token, orderID).then((res) => {
      setProductDetailsData(res.data);
      // setOrderDetailsData(location.state.orderDetails);
      setIsLoading(false);
    });
    getPaymentDetails(token, orderID).then((res) => {
      console.log(res.data);
      setPaymentDetails(res.data);

      if (res.data.subtotal <= 0) {
        // console.log("0 subtotal")

        let data = {
          is_delivery_cancel: false,
          payment_id: orderDetailsData.payment_id,
          reason_for_cancellation: "No WayBill",
        };

        // console.log(data)

        createCancelOrder(token, data).then((res) => {
          // console.log(res.data);
          toast.error("Order Canceled...!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
          });
          // document.getElementById("closeCancelModal").click();
          setTimeout(() => {
            navigate("/clothing/orders/canceled");
          }, 2500);
        });
      }
      setConfirmOrderDetails(JSON.parse(res.data.complete_shipping_address));
      setIsLoading(false);
    });
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmitCancel,
    formState: { errors: errors1 },
  } = useForm();

  const orderConfirm = (item) => {
    // console.log(item.pickupDate);
    const date = new Date(item.pickupDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const parts = date.split("/");
    const formattedDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`;

    const time = item.pickupTime;
    const [hours, minutes] = time.split(":");
    const formattedTime = `${hours.padStart(2, "0")}:${minutes.padStart(
      2,
      "0"
    )}:00`;

    // const formattedDate = selectedDate?.toDateString(selectedDate);

    // console.log(formattedDate);

    console.log(confirmOrderDetails)


    const data = {
      name: confirmOrderDetails.name,
      add: confirmOrderDetails.add,
      pin: confirmOrderDetails.pin,
      city: confirmOrderDetails.city,
      state: confirmOrderDetails.state,
      country: confirmOrderDetails.country,
      phone: confirmOrderDetails.phone,
      order: paymentDetails.order_id,
      shipment_width: item.boxWidth,
      shipment_height: item.boxHeight,
      weight: item.boxWeight,
      type: "placed_order",
      compony_id: restaurantId,
      pickup_date: formattedDate,
      pickup_time: formattedTime,
    };

    console.log(data)
    createDeliveryOrder(token, data).then((res) => {
      console.log(res.data);
      toast.success("Order Confirmed...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeConfirmModal").click();
      setTimeout(() => {
        navigate("/clothing/orders/ready");
      }, 2500);
    });

    // console.log(data);
  };

  const cancelOrder = (item) => {
    const waybillID =
      orderDetailsData.delhivery_obj && orderDetailsData.delhivery_obj.waybill;
    let data;
    if (waybillID) {
      data = {
        waybill_id: waybillID,
        is_delivery_cancel: true,
        payment_id: orderDetailsData.payment_id,
        reason_for_cancellation: item.remark,
      };
    } else {
      data = {
        is_delivery_cancel: false,
        payment_id: orderDetailsData.payment_id,
        reason_for_cancellation: item.remark,
      };
    }
    // console.log(data)

    createCancelOrder(token, data).then((res) => {
      // console.log(res.data);
      toast.error("Order Canceled...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeCancelModal").click();
      setTimeout(() => {
        navigate("/clothing/orders/canceled");
      }, 2500);
    });

    // console.log(item);
  };

  console.log(productDetailsData);
  console.log(orderDetailsData);

  const markUnavailable = (item) => {
    console.log(item);
    const data = {
      order_id: item.order.toString(),
      company_id: item.product.restaurant.toString(),
    };

    markProductUnavailable(token, item.order_item_id.toString(), data).then(
      (res) => {
        console.log(res);
        fetchOrderDetails();
      }
    );
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // console.log(date);
    setValue("pickupDate", date);
    clearErrors(["pickupDate"]);
    setIsDatePickerOpen(false); // Close DatePicker after date selection
  };

  const handleInputClick = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleConfirm = () => {
    setIsDatePickerOpen(false);
  };

  // const [value, onChange] = useState('10:00');

  return (
    <div>
      {isLoading ? ( // Conditional rendering of loader
        // <div className="spinner-border text-primary" role="status">
        //   <span className="visually-hidden">Loading...</span>
        // </div>
        <Loader />
      ) : (
        // {/* <!-- START PAGE CONTENT--> */}

        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3  mx-4">
              {/* Confirm Order Modal */}
              <div className="container ">
                <div
                  className="modal fade"
                  id="addProductDetailsModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Add Product Details Modal content--> */}
                    <form
                      id="confirmOrderForm"
                      data-form-label="confirmOrder"
                      onSubmit={handleSubmit(orderConfirm)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">ADD DETAILS</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeConfirmModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body product-details-modal">
                          <p className="fs-6 mb-4 fw-bold ms-4">
                            Are you sure you want to Confirm this Order? <br />
                            Before Order Confirmation, Please enter below
                            details.
                          </p>
                          <div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Width (In cm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxWidth", {
                                  required: "Please Enter BOX Width",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                                // onKeyPress={(e) => {
                                //   // Allow only numeric input
                                //   const isNumericInput = /^\d$/;
                                //   if (!isNumericInput.test(e.key)) {
                                //     e.preventDefault();
                                //   }
                                // }}
                              />
                              {errors.boxWidth && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxWidth.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Height (In cm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxHeight", {
                                  required: "Please Enter BOX Height",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                              />
                              {errors.boxHeight && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxHeight.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Weight (In gm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxWeight", {
                                  required: "Please Enter BOX Weight",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                              />
                              {errors.boxWeight && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxWeight.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  Pickup Date <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("pickupDate", {
                                  required: "Please Select Pickup Date",
                                })}
                                placeholder="Pickup Date"
                                onClick={handleInputClick}
                                value={
                                  selectedDate
                                    ? selectedDate.toLocaleDateString("en-US", {
                                        timeZone: "Asia/Kolkata",
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })
                                    : ""
                                }
                                style={{ paddingRight: "2.5em" }}
                              />
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className="calender-icon"
                                onClick={handleInputClick}
                              />

                              {isDatePickerOpen && (
                                <div className="datePicker">
                                  <DatePicker
                                    selected={selectedDate}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="yyyy-MM-dd" // Customize date format if needed
                                    minDate={new Date()}
                                    inline
                                  />
                                </div>
                              )}

                              {errors.pickupDate && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.pickupDate.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  Pickup Time <i className="fa fa-asterisk"></i>
                                </label>
                              </div>
                              {/* <div className="ui container-date">
                                <div className="ui calendar" id="example16">
                                  <div className="ui input left icon">
                                    <i className="time icon"></i>  */}
                              <input
                                      type="text"
                                      placeholder="Pickup Time"
                                      {...register("pickupTime", {
                                        required: "Please Select Pickup Time",
                                      })}
                                      style={{fontWeight: "normal"}}
                                    />
                                    {errors.pickupTime && (
                                      <p className="text-danger position-absolute mt-4 ms-4">
                                        {errors.pickupTime.message}
                                      </p>
                                    )}
                              {/* </div>
                                </div>
                              </div> */}
                              {/* <div className="TimePicker">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["TimePicker"]}>
                                  <TimePicker
                                    className="pickup-time"
                                    label="Pickup Time"
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div> */}
                              {/* <div>
                            <TimePicker
        // onChange={onChange}
        // value={value}
      />
      

      
                            </div> */}
                            
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-default"
                            // data-dismiss="modal"
                            // onClick={updateData}
                          >
                            CONFIRM ORDER
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Cancel Order Modal */}
              <div className="container ">
                <div className="modal fade" id="cancelOrderModal" role="dialog">
                  <div className="modal-dialog">
                    {/* <!-- Cancel Modal content--> */}
                    <form
                      id="cancelOrderForm"
                      data-form-label="cancelOrder"
                      onSubmit={handleSubmitCancel(cancelOrder)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">WARNING</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeCancelModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-6 mb-4 fw-bold">
                            Are you sure you want to Cancel this Order?
                          </p>
                          <div>
                            <div>
                              <label>
                                Add Remark <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                {...register1("remark", {
                                  required:
                                    "Please Enter Reason for Order Cancelation",
                                })}
                              />
                              {errors1.remark && (
                                <p className="text-danger position-absolute">
                                  {errors1.remark.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-default"
                            // data-dismiss="modal"
                            // onClick={addData}
                          >
                            CANCEL ORDER
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                {/* Product Calculation Tab */}
                <div className="product-calculation w-50">
                  <div className="">
                    <div>
                      {paymentDetails && (
                        <table className="billing">
                          <tr>
                            <td>Sub Total</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.subtotal}
                            </td>
                          </tr>
                          <tr>
                            <td>TAX</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.tax}
                            </td>
                          </tr>
                          <tr>
                            <td>TIP</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.tip}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipping Fee</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.shipping_fee}
                            </td>
                          </tr>
                          <tr>
                            <td>Discount</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.discount}
                            </td>
                          </tr>
                          {/* <hr/> */}
                          <div className="line"></div>
                          <tr className="fw-bolder">
                            <td>Order Total</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.total}
                            </td>
                          </tr>
                          <div className={`${hideAll ? "hidden" : ""}`}>
                            <div
                              className={`d-flex justify-content-between confirm-cancel`}
                            >
                              <button
                                hidden={hidden}
                                type="button"
                                className={`btn-confirm btn-secondary-confirm`}
                                data-toggle="modal"
                                data-target="#addProductDetailsModal"
                                onClick={handleConfirm}
                              >
                                CONFIRM
                              </button>
                              <button
                                type="button"
                                className={`btn-confirm btn-secondary-cancel`}
                                data-toggle="modal"
                                data-target="#cancelOrderModal"
                              >
                                CANCEL
                              </button>
                            </div>
                          </div>

                          {/* <Watermark /> */}
                        </table>
                      )}
                    </div>
                  </div>
                </div>

                {/* Product Details Tab */}
                <div className="productDetails w-50">
                  <div className="">
                    <div className="productDetails-top">
                      <label className="fs-4 fw-bold px-3 my-2">
                        Product Details
                      </label>
                      {orderDetailsData && (
                        <label className=" float-right orderid fs-4 fw-bold px-3 my-2">
                          {orderDetailsData.order.order_id}
                        </label>
                      )}
                    </div>

                    <div className="mb-2 p-3">
                      {paymentDetails && (
                        <small className="text-muted">
                          {new Date(paymentDetails.created_at).toLocaleString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              // hour: "numeric",
                              // minute: "numeric",
                              // second: 'numeric',
                            }
                          )}
                        </small>
                      )}
                      {productDetailsData &&
                        productDetailsData.results.map((item, i) => (
                          <div className="card mb-2 w-100 h-25 mt-2" key={i}>
                            <div className="row g-0">
                              <div className="col-md-4">
                                <img
                                  src={item.color.image_url.split('@')[0]}
                                  className="img-fluid rounded-start h-100 p-1"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-8">
                                <div className="card-body">
                                  <h5 className="card-title fs-5">
                                    {item.product.product_name}
                                  </h5>
                                  <div className="card-text">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <div>
                                          <label>
                                            Size: {item.color.size.name}
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            Color:{" "}
                                            {item.color.color.split(",")[0]}
                                          </label>
                                        </div>
                                      </div>

                                      <div>
                                        <div>
                                          <label>
                                            Quantity: {item.quantity}
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            Price: <i className="fa fa-inr"></i>
                                            {item.color.price}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-text d-flex justify-content-between mt-4">
                                    <div>
                                      <label className="fw-bold">
                                        Total: <i className="fa fa-inr"></i>
                                        {item.color.price * item.quantity}
                                      </label>
                                    </div>

                                    <div>
                                      <button
                                        disabled={
                                          disabled
                                            ? disabled
                                            : item.order_item_status ===
                                              "Available"
                                            ? false
                                            : true
                                        }
                                        type="button"
                                        className={`btn btn-light btn-sm fw-bold text-danger mt-1
                                       ${
                                         hidden
                                           ? item.order_item_status ===
                                             "Available"
                                             ? "hidden"
                                             : ""
                                           : ""
                                       }
                                      `}
                                        onClick={() => markUnavailable(item)}
                                      >
                                        {item.order_item_status === "Available"
                                          ? "Mark as Unavailable"
                                          : "Currently Unavailable"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
