import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllCoupons,
  updateSingleCoupon,
  addSingleCoupon,
  deleteSingleCoupon,
} from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Loader from "../layout/Loader";

export default function Coupons() {
  const [isButtonActive, setIsButtonActive] = useState(false); // Added state for button
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [coupons, setCoupons] = useState();
  const [updateCoupon, setUpdateCoupon] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // console.log(coupons);
  // console.log(updateCoupon);

  // Function to toggle button's active state
  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
    setIsDatePickerOpen(false);
    clearErrors1();
  };

  const fetchCoupons = () => {
    getAllCoupons(token, restaurantId).then((res) => {
      // console.log(res.data);
      setCoupons(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchCoupons();
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmitAdd,
    reset: reset1,
    setValue: setValue1,
    clearErrors: clearErrors1,
    formState: { errors: errors1 },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const [deletedItem, setDeletedItem] = useState(null);

  const handleEdit = (item) => {
    // console.log(item);
    setValue("expiry_date", item.expiry_date);
    setCouponType(item.coupon_type);
    setUpdateCoupon(item);
    setIsDatePickerOpen(false);
    clearErrors();
  };

  const updateData = (item) => {
    // console.log(item);
    const date = new Date(item.expiry_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const parts = date.split("/");
    const formattedDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`;

    updateCoupon.expiry_date = formattedDate;
    updateCoupon.coupon_type = couponType;

    let data = updateCoupon;

    // console.log(data);
    updateSingleCoupon(token, data).then((res) => {
      // console.log(res.data);

      toast.success("Coupon Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      setSelectedDate(null);
      document.getElementById("closeUpdateModal").click();
      fetchCoupons();
      setValue("expiry_date", null);
    });
  };

  const addData = (item) => {
    // console.log(item);

    const date = new Date(item.expiry_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const parts = date.split("/");
    const formattedDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`;

    item.expiry_date = formattedDate;
    item.restaurant = restaurantId;
    item.coupon_type = couponType;

    // console.log(selectedcoupons);

    const data = item;

    // console.log(data);

    addSingleCoupon(token, data).then((res) => {
      // console.log(res.data);

      toast.success("Coupon Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      document.getElementById("closeAddModal").click();
      setSelectedDate(null);
      fetchCoupons();
    });

    reset1();
  };

  const handleDelete = (item) => {
    // console.log(item);
    setDeletedItem(item);
  };

  const deleteData = () => {
    // console.log(data)
    // console.log(deletedItem);
    deleteSingleCoupon(token, deletedItem.coupon_id).then((res) => {
      // console.log(res);
      toast.error("Coupon Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeDeleteModal").click();
      fetchCoupons();
    });
  };

  const errorMessages = {
    max_coupons_allowed: {
      message: "Enter Max. Allowed Coupons",
      pattern: {
        value: /^\d*$/,
        message: "Must contain only digits",
      },
      validate: (value) => parseInt(value) > 0 || "Must be greater than 0",
    },
    coupon_code: {
      message: "Enter Coupon Code",
    },
    percent_amount_off: {
      message: "Enter Off Precrnt/Amount",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Enter Valid Precrnt/Amount",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Value must be greater than 0",
    },
    min_applicable_amount: {
      message: "Enter Min. Applicable Amt",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Enter Valid Amount",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Amount must be greater than 0",
    },
    expiry_date: {
      message: "Enter Expiry Date",
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the value is empty
    if (!value) {
      setError(name, { type: "manual", message: errorMessages[name].message });
    } else if (errorMessages[name].pattern) {
      const pattern = errorMessages[name].pattern.value;
      if (!pattern.test(value)) {
        setError(name, {
          type: "manual",
          message: errorMessages[name].pattern.message,
        });
      } else if (errorMessages[name].validate) {
        const validate = errorMessages[name].validate;
        const validationError = validate(value);
        if (validationError) {
          setError(name, { type: "manual", message: validationError });
        } else {
          clearErrors(name);
        }
      }
    } else {
      clearErrors(name);
    }

    setUpdateCoupon({
      ...updateCoupon,
      [name]: value,
    });
  };

  const [couponType, setCouponType] = useState("Percent");

  const handleRadioChange = (e) => {
    // console.log(e.target.value);
    setCouponType(e.target.value);
  };

  // const defaultDate = new Date(updateCoupon?.expiry_date);
  // console.log(defaultDate);

  const [selectedDate, setSelectedDate] = useState(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // console.log(date);
    setValue("expiry_date", date);
    clearErrors(["expiry_date"]);
    setIsDatePickerOpen(false);
  };

  const handleDateChange1 = (date) => {
    setSelectedDate(date);
    // console.log(date);
    setValue1("expiry_date", date);
    clearErrors1(["expiry_date"]);
    setIsDatePickerOpen(false);
  };

  const handleInputClick = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  // console.log(updateCoupon?.expiry_date);

  return (
    <div>
      {isLoading ? ( // Conditional rendering of loader
        // <div className="spinner-border text-primary" role="status">
        //   <span className="visually-hidden">Loading...</span>
        // </div>
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                data-toggle="modal"
                data-target="#addCategoryModal"
              >
                <i className="fa fa-plus"></i>
              </div>

              {/* <!-- Edit Modal --> */}

              <div className="container ">
                <div
                  className="modal fade updateCoupon-modal"
                  id="editCouponsModel"
                  role="dialog"
                >
                  <div className={`modal-dialog`} id="categoryModal">
                    {/* <!-- Edit Modal content--> */}
                    <form
                      id="editForm"
                      data-form-label="update"
                      onSubmit={handleSubmitUpdate(updateData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">UPDATE COUPON</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeUpdateModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}
                          {updateCoupon && (
                            <div>
                              <div className="d-flex mb-3">
                                <div className="w-50 me-2">
                                  <label>
                                    Coupon Type{" "}
                                    <i className="fa fa-asterisk"></i>
                                  </label>

                                  <div className="d-flex radio-button mt-1">
                                    <div className="form-check me-4">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault1"
                                        value="Amount"
                                        onChange={() => {}}
                                        onClick={handleRadioChange}
                                        checked={couponType === "Amount"}
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault1"
                                      >
                                        Amount
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        value="Percent"
                                        onChange={() => {}}
                                        onClick={handleRadioChange}
                                        checked={couponType === "Percent"}
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexRadioDefault2"
                                      >
                                        Percent
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="w-50 ms-4">
                                  <label>
                                    Coupon Code{" "}
                                    <i className="fa fa-asterisk"></i>
                                  </label>

                                  <input
                                    value={updateCoupon.coupon_code}
                                    {...register("coupon_code", {
                                      required: "Enter Coupon Code",
                                    })}
                                    onChange={handleInputChange}
                                  />
                                  {errors.coupon_code && (
                                    <p className="text-danger position-absolute">
                                      {errors.coupon_code.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="d-flex mb-3">
                                <div className="w-50 me-2">
                                  <label>
                                    Coupon{" "}
                                    {couponType === "Amount" ? (
                                      <>
                                        Amount ( <i className="fa fa-inr"></i> )
                                      </>
                                    ) : (
                                      <>
                                        Percent ({" "}
                                        <i className="fa fa-percent"></i> )
                                      </>
                                    )}
                                    <i className="fa fa-asterisk"></i>
                                  </label>

                                  <input
                                    value={updateCoupon.percent_amount_off}
                                    {...register("percent_amount_off", {
                                      required: "Enter Off Percent or Amount ",
                                    })}
                                    onChange={handleInputChange}
                                  />
                                  {errors.percent_amount_off && (
                                    <p className="text-danger position-absolute">
                                      {errors.percent_amount_off.message}
                                    </p>
                                  )}
                                </div>

                                <div className="w-50 ms-4">
                                  <label>
                                    Min. Applicable Amt ({" "}
                                    <i className="fa fa-inr"></i> ){" "}
                                    <i className="fa fa-asterisk"></i>
                                  </label>

                                  <input
                                    value={updateCoupon.min_applicable_amount}
                                    {...register("min_applicable_amount", {
                                      required: "Enter Min. Applicable Amt",
                                    })}
                                    onChange={handleInputChange}
                                  />
                                  {errors.min_applicable_amount && (
                                    <p className="text-danger position-absolute">
                                      {errors.min_applicable_amount.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="d-flex mb-3">
                                <div className="w-50 me-2">
                                  <label>
                                    Expiry Date{" "}
                                    <i className="fa fa-asterisk"></i>
                                  </label>

                                  <input
                                    {...register("expiry_date", {
                                      required: "Enter Expiry Date",
                                    })}
                                    onClick={handleInputClick}
                                    value={
                                      selectedDate
                                        ? selectedDate
                                            .toLocaleDateString("en-US", {
                                              day: "numeric",
                                              month: "short",
                                              year: "numeric",
                                            })
                                            .replace(
                                              /(\d+) (\w+), (\d+)/,
                                              "$1 $2 $3"
                                            )
                                        : new Date(updateCoupon.expiry_date)
                                            .toLocaleDateString("en-US", {
                                              day: "numeric",
                                              month: "short",
                                              year: "numeric",
                                            })
                                            .replace(
                                              /(\d+) (\w+), (\d+)/,
                                              "$1 $2 $3"
                                            )
                                    }

                                    // onChange={handleInputChange}
                                  />
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="calender-icon-coupons"
                                    onClick={handleInputClick}
                                  />
                                  {isDatePickerOpen && (
                                    <div className="datePicker">
                                      <DatePicker
                                        selected={selectedDate}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="yyyy-MM-dd" // Customize date format if needed
                                        minDate={new Date()}
                                        inline
                                      />
                                    </div>
                                  )}

                                  {errors.expiry_date && (
                                    <p className="text-danger position-absolute">
                                      {errors.expiry_date.message}
                                    </p>
                                  )}
                                </div>

                                <div className="w-50 ms-4">
                                  <label>
                                    Max. Allowed Coupon{" "}
                                    <i className="fa fa-asterisk"></i>
                                  </label>

                                  <input
                                    value={updateCoupon.max_coupons_allowed}
                                    {...register("max_coupons_allowed", {
                                      required: "Enter Max. Allowed Coupons",
                                    })}
                                    onChange={handleInputChange}
                                  />
                                  {errors.max_coupons_allowed && (
                                    <p className="text-danger position-absolute">
                                      {errors.max_coupons_allowed.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-default">
                            UPDATE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* <!-- Add Modal --> */}

              <div className="container ">
                <div className="modal fade" id="addCategoryModal" role="dialog">
                  <div className="modal-dialog">
                    {/* <!-- Add Modal content--> */}
                    <form
                      id="addForm"
                      data-form-label="add"
                      onSubmit={handleSubmitAdd(addData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">ADD COUPON</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeAddModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}
                          <div>
                            <div className="d-flex mb-3">
                              <div className="w-50 me-2">
                                <label>
                                  Coupon Type <i className="fa fa-asterisk"></i>
                                </label>

                                <div className="d-flex radio-button mt-1">
                                  <div className="form-check me-4">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      value="Amount"
                                      onChange={() => {}}
                                      onClick={handleRadioChange}
                                      checked={couponType === "Amount"}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      Amount
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      value="Percent"
                                      onChange={() => {}}
                                      onClick={handleRadioChange}
                                      checked={couponType === "Percent"}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      Percent
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="w-50 ms-4">
                                <label>
                                  Coupon Code <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register1("coupon_code", {
                                    required: "Enter Coupon Code",
                                  })}
                                />
                                {errors1.coupon_code && (
                                  <p className="text-danger position-absolute">
                                    {errors1.coupon_code.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="d-flex mb-3">
                              <div className="w-50 me-2">
                                <label>
                                  Coupon{" "}
                                  {couponType === "Amount" ? (
                                    <>
                                      Amount ( <i className="fa fa-inr"></i> )
                                    </>
                                  ) : (
                                    <>
                                      Percent ({" "}
                                      <i className="fa fa-percent"></i> )
                                    </>
                                  )}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register1("percent_amount_off", {
                                    required: "Enter Off Percent or Amount ",
                                  })}
                                />
                                {errors1.percent_amount_off && (
                                  <p className="text-danger position-absolute">
                                    {errors1.percent_amount_off.message}
                                  </p>
                                )}
                              </div>

                              <div className="w-50 ms-4">
                                <label>
                                  Min. Applicable Amt ({" "}
                                  <i className="fa fa-inr"></i> ){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register1("min_applicable_amount", {
                                    required: "Enter Min. Applicable Amt",
                                  })}
                                />
                                {errors1.min_applicable_amount && (
                                  <p className="text-danger position-absolute">
                                    {errors1.min_applicable_amount.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="d-flex mb-3">
                              <div className="w-50 me-2">
                                <label>
                                  Expiry Date <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register1("expiry_date", {
                                    required: "Enter Expiry Date",
                                  })}
                                  onClick={handleInputClick}
                                  value={
                                    selectedDate
                                      ? selectedDate
                                          .toLocaleDateString("en-US", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                          })
                                          .replace(
                                            /(\d+) (\w+), (\d+)/,
                                            "$1 $2 $3"
                                          )
                                      : ""
                                  }
                                />
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="calender-icon-coupons"
                                  onClick={handleInputClick}
                                />
                                {isDatePickerOpen && (
                                  <div className="datePicker">
                                    <DatePicker
                                      selected={selectedDate}
                                      value={selectedDate}
                                      onChange={handleDateChange1}
                                      dateFormat="yyyy-MM-dd" // Customize date format if needed
                                      minDate={new Date()}
                                      inline
                                    />
                                  </div>
                                )}

                                {errors1.expiry_date && (
                                  <p className="text-danger position-absolute">
                                    {errors1.expiry_date.message}
                                  </p>
                                )}
                              </div>

                              <div className="w-50 ms-4">
                                <label>
                                  Max. Allowed Coupon{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  {...register1("max_coupons_allowed", {
                                    required: "Enter Max. Allowed Coupons",
                                  })}
                                />
                                {errors1.max_coupons_allowed && (
                                  <p className="text-danger position-absolute">
                                    {errors1.max_coupons_allowed.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-default">
                            ADD
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* <!-- Delete Modal --> */}
              <div className="container ">
                <div
                  className="modal fade"
                  id="deleteCategoryModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Delete Modal content--> */}
                    <form
                      id="deleteForm"
                      data-form-label="delete"
                      onSubmit={handleSubmitDelete(deleteData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">WARNING</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeDeleteModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-5">
                            Are you sure, You want to delete this coupon?
                          </p>

                          {deletedItem && (
                            <div>
                              <input
                                hidden
                                value={deletedItem.coupon_id}
                                {...register2("coupon_id")}
                              />
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-default">
                            DELETE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between my-3">
                <table
                  className="table table-striped table-bordered table-hover coupon-table"
                  cellSpacing="0"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Code</th>
                      <th>Type</th>
                      <th>Discount</th>
                      <th>Min Amt</th>
                      <th>Expiry Date</th>
                      <th>Max Allowed</th>
                      <th>Used Code(s)</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coupons &&
                      coupons.map((item, i) => (
                        <tr className="draggingItem tr" key={i}>
                          <td>{i + 1}</td>
                          <td>{item.coupon_code}</td>
                          <td>{item.coupon_type}</td>
                          <td>
                            {item.coupon_type === "Amount" ? (
                              <>
                                <i className="fa fa-inr"></i>
                                {item.percent_amount_off}
                              </>
                            ) : (
                              <>
                                {item.percent_amount_off}
                                <i className="fa fa-percent"></i>
                              </>
                            )}
                          </td>
                          <td>
                            <i className="fa fa-inr"></i>
                            {item.min_applicable_amount}
                          </td>
                          <td>
                            {new Date(item.expiry_date)
                              .toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })
                              .replace(/(\d+) (\w+), (\d+)/, "$1 $2 $3")}
                          </td>
                          <td>{item.max_coupons_allowed}</td>
                          <td>{item.used_coupons}</td>
                          <td>
                            {new Date(item.created_at)
                              .toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })
                              .replace(/(\d+) (\w+), (\d+)/, "$1 $2 $3")}
                          </td>
                          <td>
                            <Link
                              to="#"
                              onClick={() => handleEdit(item)}
                              data-toggle="modal"
                              data-target="#editCouponsModel"
                            >
                              <i className="fa fa-pencil popup me-4 pencil-small"></i>
                            </Link>
                            <Link
                              to="#"
                              onClick={() => handleDelete(item)}
                              data-toggle="modal"
                              data-target="#deleteCategoryModal"
                            >
                              <i className="fa fa-trash popup"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
