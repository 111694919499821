import React from "react";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Orders from "./components/orders/Orders";
import Login from "./components/layout/Login";
import Ecommerce from "./components/Ecommerce";
import Category from "./components/category/Category";
import SubCategory from "./components/subCategory/SubCategory";
import ProductDetails from "./components/orders/pages/ProductDetails";
import NewOrders from "./components/orders/pages/NewOrders";
import AcceptedOrders from "./components/orders/pages/AcceptedOrders";
import DeliveredOrders from "./components/orders/pages/DeliveredOrders";
import CancelledOrders from "./components/orders/pages/CancelledOrders";
import TrackOrder from "./components/orders/pages/TrackOrder";
import Products from "./components/products/Products";
import UpdateProduct from "./components/products/pages/UpdateProduct";
import AddProduct from "./components/products/pages/AddProduct";
import AddAddonProduct from "./components/products/pages/AddAddonProduct";
import AddonProduct from "./components/products/pages/AddonProduct";
import Specification from "./components/products/pages/Specification";
import Coupons from "./components/coupons/Coupons";
import Setup from "./components/setup/Setup";
import Subscription from "./components/subscription/Subscription";
import Support from "./components/support/Support";

export default function Router() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/clothing" element={<Ecommerce />}>
            <Route path="/clothing/orders" element={<Orders />}>
              <Route path="/clothing/orders/new" element={<NewOrders />} />
              <Route
                path="/clothing/orders/ready"
                element={<AcceptedOrders />}
              />
              <Route
                path="/clothing/orders/delivered"
                element={<DeliveredOrders />}
              />
              <Route
                path="/clothing/orders/canceled"
                element={<CancelledOrders />}
              />
            </Route>
            <Route
              path="/clothing/product-details"
              element={<ProductDetails />}
            />
            <Route path="/clothing/track-order" element={<TrackOrder />} />
            <Route path="/clothing/category" element={<Category />} />
            <Route path="/clothing/sub-category" element={<SubCategory />} />
            <Route path="/clothing/products" element={<Products />} />
            <Route path="/clothing/update-product" element={<UpdateProduct />} />
            <Route path="/clothing/add-product" element={<AddProduct />} />
            <Route path="/clothing/addon-product" element={<AddonProduct />} />
            <Route path="/clothing/add-addon-product" element={<AddAddonProduct />} />
            <Route path="/clothing/specification" element={<Specification />} />
            <Route path="/clothing/coupons" element={<Coupons />} />
            <Route path="/clothing/setup" element={<Setup />} />
            <Route path="/clothing/subscription" element={<Subscription />} />
            <Route path="/clothing/support" element={<Support />} />

          </Route>
        </Routes>
      </BrowserRouter>
  );
}
