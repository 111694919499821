import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearToken, logoutSession } from "../redux/actions/Actions";

export default function Ecommerce() {
  let dispatch = useDispatch();

  // const [active,setActive] = useState(true);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    window.scrollTo(0, 1);
    // Set the active tab based on the current location pathname
    const path = location.pathname;
    setActiveTab(getTabFromPath(path));
  }, [location.pathname]);

  const getTabFromPath = (path) => {
    if (path.includes("/clothing/orders")) return "orders";
    if (path.includes("/clothing/category")) return "category";
    if (path.includes("/clothing/sub-category")) return "subCategory";
    if (path.includes("/clothing/products")) return "products";
    if (path.includes("/clothing/coupons")) return "coupons";
    if (path.includes("/clothing/setup")) return "setup";
    if (path.includes("/clothing/subscription")) return "subscription";
    if (path.includes("/clothing/support")) return "support";
    if (path.includes("/clothing/product-details")) return "orders";
    if (path.includes("/clothing/track-order")) return "orders";
    if (path.includes("/clothing/update-product")) return "products";
    if (path.includes("/clothing/add-product")) return "products";
    if (path.includes("/clothing/addon-product")) return "products";
    if (path.includes("/clothing/add-addon-product")) return "products";
    if (path.includes("/clothing/specification")) return "products";

    return "";
  };

  // const handleTabClick = () => {
  //   setActive(false);
  // };

  const Logout = () => {
    dispatch(clearToken());
    dispatch(logoutSession());
    setTimeout(() => {
      navigate("/");
    }, 10);
  };

  const toggleSidebar = () => {
    setIsSidebarMinimized((prev) => !prev);
  };

  return (
    <div>
      {/* <div id="body" className="fixed-navbar"> */}
      <div
        id="body"
        className={`fixed-navbar ${
          isSidebarMinimized ? "sidebar-minimized" : ""
        }`}
      >
        <div className="page-wrapper" id="page">
          {/* <!-- START HEADER--> */}
          <header className="header">
            <div className="page-brand">
              <Link className="link mt-4 ms-5 p-2" to="/clothing/orders/new">
                Dashboard{" "}
                <i
                  className="fa fa-bars ms-3"
                  onClick={() => toggleSidebar()}
                ></i>
                <span className="brand">
                  {/* InstaApp Admin */}

                  {/* <img src="/assets/img/logo.png" /> */}
                </span>
              </Link>
            </div>
            <div className="flexbox flex-1">
              {/* <!-- START TOP-LEFT TOOLBAR--> */}
              <ul className="nav navbar-toolbar"></ul>
              {/* <!-- END TOP-LEFT TOOLBAR--> */}
              {/* <!-- START TOP-RIGHT TOOLBAR--> */}

              <ul className="nav navbar-toolbar">
                <div className="row">
                  <div className="col-9"></div>
                  <div className="px-4 w-25 h-25 col-2 logo">
                    <img
                      className="px-3 ms-5 float-left"
                      src="/assets/img/logo.png"
                      alt="logo"
                    />
                  </div>
                  <div className="w-25 h-25 col-1 mt-1 logo-2">
                    <Link to="/clothing/support">
                      <img
                        className="w-50 h-50 ms-3"
                        src="/assets/img/support.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>

                {/* <li className="dropdown dropdown-user">
                  <a
                    className="nav-link dropdown-toggle link"
                    data-toggle="dropdown"
                  >
                    <img src="/assets/img/admin-avatar.png" />

                    <span></span>Admin<i className="fa fa-angle-down m-l-5"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="profile.html">
                      <i className="fa fa-user"></i>Profile
                    </a>
                    <a className="dropdown-item" href="profile.html">
                      <i className="fa fa-cog"></i>Settings
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-support"></i>Support
                    </a>
                    <li className="dropdown-divider"></li>
                    <a className="dropdown-item" href="login.html">
                      <i className="fa fa-power-off"></i>Logout
                    </a>
                  </ul>
                </li> */}
              </ul>
              {/* <!-- END TOP-RIGHT TOOLBAR--> */}
            </div>
          </header>
          {/* <!-- END HEADER--> */}
          {/* <!-- START SIDEBAR--> */}

          <nav className="page-sidebar" id="sidebar">
            <div id="sidebar-collapse">
              <div className="admin-block d-flex"></div>
              <ul className="side-menu metismenu ms-4 fs-5">
                <li
                  // onClick={() => setActiveTab("orders")}
                  className={`${activeTab === "orders" ? "active" : ""}`}
                >
                  <Link to="/clothing/orders/new">
                    <i className="sidebar-item-icon fa fa-th-list me-2"></i>
                    <span className="nav-label">Orders</span>
                  </Link>
                </li>
                <li
                  // onClick={() => setActiveTab("category")}
                  className={`mb-3 mt-3 ${
                    activeTab === "category" ? "active" : ""
                  }`}
                >
                  <Link to="/clothing/category">
                    <i className="sidebar-item-icon fa fa-th-large me-2"></i>
                    <span className="nav-label">Category</span>
                  </Link>
                </li>
                <li
                  // onClick={() => setActiveTab("subCategory")}
                  className={`mb-3 mt-3 ${
                    activeTab === "subCategory" ? "active" : ""
                  }`}
                >
                  <Link to="/clothing/sub-category">
                    <i className="sidebar-item-icon fa fa-th me-2"></i>
                    <span className="nav-label">Sub Category</span>
                  </Link>
                </li>
                <li
                  // onClick={() => setActiveTab("products")}
                  className={`mb-3 mt-3 ${
                    activeTab === "products" ? "active" : ""
                  }`}
                >
                  <Link to="/clothing/products">
                    <i className="sidebar-item-icon fa fa-product-hunt me-2"></i>
                    <span className="nav-label">Products</span>
                  </Link>
                </li>
                <li
                  // onClick={() => setActiveTab("coupons")}
                  className={`mb-3 mt-3 ${
                    activeTab === "coupons" ? "active" : ""
                  }`}
                >
                  <Link to="/clothing/coupons">
                    <i className="sidebar-item-icon fa fa-tags me-2"></i>
                    <span className="nav-label">Coupons</span>
                  </Link>
                </li>

                <li
                  // onClick={() => setActiveTab("setup")}
                  className={`mb-3 mt-3 ${
                    activeTab === "setup" ? "active" : ""
                  }`}
                >
                  <Link to="/clothing/setup">
                    <i className="sidebar-item-icon fa fa-edit me-2"></i>
                    <span className="nav-label">Setup</span>
                  </Link>
                </li>
                <li
                  // onClick={() => setActiveTab("subscription")}
                  className={`mb-3 mt-3 ${
                    activeTab === "subscription" ? "active" : ""
                  }`}
                >
                  <Link to="/clothing/subscription">
                    <i className="sidebar-item-icon fa fa-credit-card-alt me-2"></i>
                    <span className="nav-label">Subscription</span>
                  </Link>
                </li>

                <li
                  // onClick={() => setActiveTab("support")}
                  className={`mb-3 mt-3 ${
                    activeTab === "support" ? "active" : ""
                  }`}
                >
                  <Link to="/clothing/support">
                    <i className="sidebar-item-icon fa fa-user-circle me-2"></i>
                    <span className="nav-label">Support</span>
                  </Link>
                </li>

                <li className="mb-3 mt-3" onClick={() => Logout()}>
                  <Link to="/">
                    <i className="sidebar-item-icon fa fa-sign-out me-2"></i>
                    <span className="nav-label">Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          {/* <!-- END SIDEBAR--> */}
          <div className="content-wrapper">
            {/* <!-- START PAGE CONTENT--> */}
            <Outlet />
            {/* <!-- END PAGE CONTENT--> */}
          </div>
        </div>
        {/* <!-- BEGIN PAGA BACKDROPS--> */}
        <div className="sidenav-backdrop backdrop"></div>
        {/* <div className="preloader-backdrop">
          <div className="page-preloader">Loading</div>
        </div> */}
        {/* <!-- END PAGA BACKDROPS--> */}
      </div>
    </div>
  );
}
