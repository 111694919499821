import axios from "axios";

// const dev = "https://restaurant60-be-dev-xtpocjmkpa-uw.a.run.app"; // GitHub
const dev = "https://instaapp-dev-xtpocjmkpa-uw.a.run.app" // Bit Bucket

export const adminLogin = async (data) => {
  const url = `${dev}/restaurantadmins/login/`;

  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    if (error.response && (error.response.status >= 400) && (error.response.status < 500)) {
      // Handle unauthorized access, for example, redirect to login page
      console.log("Unauthorized access. Redirecting to login page.");
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
};

export const generateToken = async () => {
  const url = `${dev}/rest-auth/login/v1/`;
  const data = {
    username: "admin",
    password: "Mphasis8",
    restaurant_id: 1,
  };

  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
