import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addProductSizeColor } from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Select from "react-select";
import Loader from "../../layout/Loader";

export default function AddAddonProduct() {
  let location = useLocation();
  let navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [productColorSize, setProductColorSize] = useState();
  const [productID, setProductID] = useState();
  const [productDetails, setProductDetails] = useState();
  const [uploadImages, setUploadImages] = useState([]);
  const [items, setItems] = useState([{ name: "", price: "", quantity: "" }]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.productColorSize) {
      const initialAddProduct = { ...location.state.productColorSize };
      setProductColorSize(initialAddProduct);
      setProductID(location.state.product_id);
      setProductDetails({
        product_id: location.state.product_id,
        initialAddProduct,
      });
      setIsLoading(false);
    }
  }, []);

  const {
    register,
    setError,
    reset,
    clearErrors,
    setValue,
    handleSubmit: handleSubmitAdd,
    formState: { errors },
  } = useForm();

  const colorOptions = [
    {
      name: "Burgundy",
      code: "#800020",
    },
    {
      name: "Navy Blue",
      code: "#000080",
    },
    {
      name: "Olive Green",
      code: "#556B2F",
    },
    {
      name: "Charcoal Gray",
      code: "#36454F",
    },
    {
      name: "Mauve",
      code: "#E0B0FF",
    },
    {
      name: "Mustard",
      code: "#FFDB58",
    },
    {
      name: "Teal Blue",
      code: "#367588",
    },
    {
      name: "Coral",
      code: "#FF7F50",
    },
    {
      name: "Taupe",
      code: "#483C32",
    },
    {
      name: "Blush Pink",
      code: "#FFB6C1",
    },
    {
      name: "Sage Green",
      code: "#9DC183",
    },
    {
      name: "Dusty Rose",
      code: "#C0737A",
    },
    {
      name: "Mocha",
      code: "#8B735B",
    },
    {
      name: "Denim Blue",
      code: "#1560BD",
    },
    {
      name: "Ivory",
      code: "#FFFFF0",
    },
    {
      name: "Slate Blue",
      code: "#6A5ACD",
    },
    {
      name: "Terracotta",
      code: "#E2725B",
    },
    {
      name: "Cognac",
      code: "#D2691E",
    },
    {
      name: "Hunter Green",
      code: "#355E3B",
    },
    {
      name: "Lilac",
      code: "#C8A2C8",
    },
    {
      name: "Steel Gray",
      code: "#6B6B6B",
    },
    {
      name: "Rose Gold",
      code: "#B76E79",
    },
    {
      name: "Copper",
      code: "#B87333",
    },
    {
      name: "Royal Blue",
      code: "#4169E1",
    },
    {
      name: "Pearl White",
      code: "#F0F0F0",
    },
    {
      name: "Silver",
      code: "#C0C0C0",
    },
    {
      name: "Gold",
      code: "#FFD700",
    },
    {
      name: "Peach",
      code: "#FFE5B4",
    },
    {
      name: "Burnt Orange",
      code: "#CC5500",
    },
  ];

  const [selectedColor, setSelectedColor] = useState(null);

  // console.log(productDetails);

  const handleChangeColor = (selectedOption) => {
    setSelectedColor(selectedOption);
    // If you're using React Hook Form, you can register the selected value
    // console.log(selectedOption);
    register("color");
  };

  const handleChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], [field]: value };
    setItems(newItems);
  };

  const addRow = () => {
    setItems([...items, { name: "", price: "", quantity: "" }]);
  };

  const subRow = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  // console.log(categoryId);

  //   console.log(subCategoryData);
  //   console.log(masterCategory);
  // console.log(productColorSize);
  // console.log(productID);

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files.length > 0) {
      const newImages = Array.from(input.files);

      // Update the state to include new images
      setUploadImages([...uploadImages, ...newImages]);

      // Optionally, you can display the uploaded images here
      newImages.forEach((file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          // Display the uploaded image
          const img = document.createElement("img");
          img.src = e.target.result;
          document.getElementById("displayedImagesAdd").appendChild(img);
        };
        reader.readAsDataURL(file);
      });

      setError("uploadImages", { type: "manual", message: "" });
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  const addData = (item) => {
    // console.log(item);
    // console.log(items);

    const data = {
      // product_name: productColorSize.product_name,
      color: `${item.color.name},${item.color.code}`,
      size: JSON.stringify(items),
      // product_id: productColorSize.color_obj_dict[0].product,
      product_id: productID,

      image: uploadImages,
    };

    // console.log(data);

    addProductSizeColor(token, data).then((res) => {
      // console.log(res.data);

      toast.success("Product Size Color Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });
    // reset({ uploadImages: true });
    // setUploadImages(null);
    setTimeout(() => {
      navigate("/clothing/addon-product", {
        state: {
          productDetails: productDetails,
        },
      });
    }, 3000);
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitAdd(addData)}
              >
                <div className="modal-content product-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">
                      ADD PRODUCT COLOR, SIZE & MEDIA
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body product-data">
                    {/* <p>Some text in the modal.</p> */}
                    {productColorSize && (
                      <table className="addon-table">
                        <tbody className="mb-5">
                          <tr>
                            <td>
                              <label>
                                Product <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={productColorSize.product_name}
                                disabled
                                {...register("product_name")}
                              />
                              {errors.product_name && (
                                <p className="text-danger">
                                  {errors.product_name.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Color <i className="fa fa-asterisk"></i>
                              </label>

                              <div>
                                <Select
                                  options={colorOptions}
                                  value={selectedColor}
                                  {...register("color", {
                                    required: "Select Color",
                                  })}
                                  placeholder="Select color"
                                  onChange={(selectedOption) => {
                                    handleChangeColor(selectedOption);
                                    setValue("color", selectedOption);
                                    clearErrors("color"); // This clears the validation error once an option is selected
                                  }}
                                  isSearchable={true}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.code}
                                />
                              </div>

                              {errors.color && (
                                <p className="text-danger">
                                  {errors.color.message}
                                </p>
                              )}
                            </td>
                          </tr>
                          {items.map((item, index) => (
                            <tr key={index} className="color-size-row">
                              <td>
                                <label>
                                  Size <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  // value={item.name}
                                  {...register(`name${index}`, {
                                    required: "Enter Size",
                                  })}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    // const pattern =
                                    //   /^(XXXS|XXS|XS|S|M|L|XL|XXL|XXXL|24|26|28|30|32|34|36|38)$/;

                                    if (value) {
                                      // if (pattern.test(value)) {
                                        clearErrors(`name${index}`);
                                      // } else {
                                      //   setError(`name${index}`, {
                                      //     type: "manual",
                                      //     message: "Invalid size format",
                                      //   });
                                      // }
                                    } else {
                                      setError(`name${index}`, {
                                        type: "manual",
                                        message: "Enter Size",
                                      });
                                    }

                                    handleChange(index, "name", value);
                                  }}
                                />
                                {errors[`name${index}`] && (
                                  <p className="text-danger position-absolute">
                                    {errors[`name${index}`].message}
                                  </p>
                                )}
                              </td>

                              <td>
                                <label>
                                  Price ( <i className="fa fa-inr"> ) </i>
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  //  value={item.price}
                                  {...register(`price${index}`, {
                                    required: "Enter Price",
                                  })}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const pattern = /^\d*\.?\d*$/;

                                    if (value) {
                                      if (pattern.test(value)) {
                                        if (parseFloat(value) > 0) {
                                          clearErrors(`price${index}`);
                                        } else {
                                          setError(`price${index}`, {
                                            type: "manual",
                                            message: "Price must be > than 0",
                                          });
                                        }
                                      } else {
                                        setError(`price${index}`, {
                                          type: "manual",
                                          message: "Please Enter Valid Price",
                                        });
                                      }
                                    } else {
                                      setError(`price${index}`, {
                                        type: "manual",
                                        message: "Enter Price",
                                      });
                                    }

                                    handleChange(index, "price", value);
                                  }}
                                />
                                {errors[`price${index}`] && (
                                  <p className="text-danger position-absolute">
                                    {errors[`price${index}`].message}
                                  </p>
                                )}
                              </td>
                              <td>
                                <label>
                                  Available QTY{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  //  value={item.quantity}
                                  {...register(`quantity${index}`, {
                                    required: "Enter QTY",
                                  })}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const pattern = /^\d*$/;
                                    if (value) {
                                      if (pattern.test(value)) {
                                        if (parseInt(value) >= 0) {
                                          clearErrors(`quantity${index}`);
                                        } else {
                                          setError(`quantity${index}`, {
                                            type: "manual",
                                            message:
                                              "Quantity must be valid",
                                          });
                                        }
                                      } else {
                                        setError(`quantity${index}`, {
                                          type: "manual",
                                          message: "Enter Valid Quantity",
                                        });
                                      }
                                    } else {
                                      setError(`quantity${index}`, {
                                        type: "manual",
                                        message: "Enter Quantity",
                                      });
                                    }
                                    handleChange(index, "quantity", value);
                                  }}
                                />
                                {errors[`quantity${index}`] && (
                                  <p className="text-danger position-absolute">
                                    {errors[`quantity${index}`].message}
                                  </p>
                                )}
                              </td>
                              <td>
                                {index === items.length - 1 && (
                                  <div className="d-flex">
                                    <div
                                      className={`add-addon-button`}
                                      onClick={addRow}
                                    >
                                      <i className="fa fa-plus ps-2 mt-2 fs-3"></i>
                                    </div>

                                    {index !== 0 && (
                                      <div
                                        className={`sub-addon-button`}
                                        onClick={() => subRow(index)}
                                      >
                                        <i className="fa fa-minus ps-2 mt-2 fs-3"></i>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {productColorSize && (
                      <table className="mt-4 addon-custom-table">
                        <tbody>
                          <tr>
                            <td>
                              <label className="me-5">
                                Upload Images <i className="fa fa-asterisk"></i>
                              </label>

                              {/* Hidden file input */}

                              <input
                                type="file"
                                id="imageInputAdd"
                                accept="image/*"
                                multiple // Allow multiple file selection
                                {...register("uploadImages", {
                                  required: "Please Upload Images",
                                })}
                                style={{ display: "none" }}
                                onChange={addImage}
                              />

                              {/* Displayed image */}

                              <img
                                src={"/assets/img/custom/uploadImage.png"}
                                alt="Upload"
                                onClick={() => handleAddImageClick()}
                              />

                              {errors.uploadImages && (
                                <p className="text-danger position-absolute img-error">
                                  {errors.uploadImages.message}
                                </p>
                              )}
                            </td>

                            <td id="displayedImagesAdd"></td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-default">
                      ADD PRODUCT COLOR, SIZE & MEDIA
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
