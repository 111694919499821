import axios from "axios";

// const dev = "https://restaurant60-be-dev-xtpocjmkpa-uw.a.run.app"; // GitHub
const dev = "https://instaapp-dev-xtpocjmkpa-uw.a.run.app" // Bit Bucket


// ------------------------------ Restaurant Admin -----------------------------

export const restaurantAdmins = async (token, username, status) => {
  console.log(token, username, status);

  const url = `${dev}/restaurantadmin/?username=${username}&status=${status}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getRestaurantDetails = async (token, restaurant_id) => {
  const url = `${dev}/restaurant/${restaurant_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateBusinessDetails = async (token, data, restaurant_id) => {
  const url = `${dev}/restaurant/${restaurant_id}/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append(
        "restaurant_url",
        `https://storage.googleapis.com/restaurant60-dev-media/${data[key].name}`
      );
    } else if (data[key] === null) {
      // Don't Append other data
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getPlanDetails = async (token, restaurant_id) => {
  const url = `${dev}/restaurants/${restaurant_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getSubscriptionDetails = async (token, restaurant_id, plan_id) => {
  const url = `${dev}/subscriptions/?restaurant_id=${restaurant_id}&plan_id=${plan_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// export const createSubscription = async (customer_id) => {
//   try {
//     const planId = 'plan_NYJeRQP8iRBr7M'; // Replace with your plan ID
//     const apiKey = 'rzp_test_b4YXoasdFeVMUB'; // Replace with your Razorpay API key
//     const apiSecret = 'ztv03jixBnjlkluxkudOrqfp'; // Replace with your Razorpay API secret

//     const url = 'https://api.razorpay.com/v1/subscriptions';
//     const authString = `Basic ${Buffer.from(apiKey + ':' + apiSecret).toString('base64')}`;

//     const requestData = {
//       plan_id: planId,
//       customer_notify: 1,
//       total_count: -1,
//       customer_id: customer_id,
//       start_at: Math.floor(Date.now() / 1000),
//       notify_url: 'your_notify_url', // Optional: Replace with your webhook endpoint to receive notifications
//       notes: {
//         key: apiKey,
//       },
//     };

//     const config = {
//       headers: {
//         Authorization: authString,
//         'Content-Type': 'application/json',
//       },
//     };

//     const response = await axios.post(url, requestData, config);
//     return response.data;
//   } catch (error) {
//     if (error.response && error.response.data && error.response.data.error) {
//       throw new Error(error.response.data.error.description);
//     } else {
//       throw new Error('An error occurred while creating subscription.');
//     }
//   }
// };

// export const createSubscription = async (res) => {
//   try {
// console.log(res)
//     const planId = 'plan_NYJfJaLlBEKAMk'; // Replace with your plan ID
//     const apiKey = 'rzp_test_b4YXoasdFeVMUB'; // Replace with your Razorpay API key
//     const apiSecret = 'ztv03jixBnjlkluxkudOrqfp'; // Replace with your Razorpay API secret

//     const url = 'https://api.razorpay.com/v1/subscriptions';
//     // const authString = `Basic ${Buffer.from(apiKey + ':' + apiSecret).toString('base64')}`;
//     const authString = `Basic Og==${btoa(apiKey + ':' + apiSecret)}`;

//     const requestData = {
//       plan_id: planId,
//       // subscription_id: planId,
//       customer_notify: 1,
//       // customer_id: '',
//       total_count :6,
//       quantity: 1,
//       start_at: Math.floor(Date.now() / 1000),
//       notify_url: 'your_notify_url', // Optional: Replace with your webhook endpoint to receive notifications
//       notes: {
//         key: apiKey,
//       },
//     };

//     const config = {
//       headers: {
//         Authorization: authString,
//         'Content-Type': 'application/json',
//       },
//     };

//     const response = await axios.post(url, requestData, config);
//     console.log(response)
//     return response.data;
//   } catch (error) {
//     if (error.response && error.response.data && error.response.data.error) {
//       console.log(error)
//       throw new Error(error.response.data.error.description);
//     } else {
//       console.log(error)
//       throw new Error('An error occurred while creating subscription.');
//     }
//   }
// };



// ------------------------------- Orders Tab ------------------------------------

export const createSubscription = async () => {
  try {
    const planId = 'plan_NYJfJaLlBEKAMk'; // Replace with your plan ID
    const apiKey = 'rzp_test_b4YXoasdFeVMUB'; // Replace with your Razorpay API key
    const apiSecret = 'ztv03jixBnjlkluxkudOrqfp'; // Replace with your Razorpay API secret

    const url = 'https://api.razorpay.com/v1/subscriptions';
    const authString = `Basic ${btoa(apiKey + ':' + apiSecret)}`;

    const requestData = {
      key: 'rzp_test_b4YXoasdFeVMUB', // Replace with your Razorpay key ID (rzp_test_b4YXoasdFeVMUB) (rzp_live_HFx9Cp8gyRM8eb)
      key_secret: 'ztv03jixBnjlkluxkudOrqfp',
      amount: 3500 * 100, // Amount in paisa (e.g., 10000 paisa = ₹100)
      currency: 'INR',
      name: 'InstaApp',
      description: 'Subscription Payment',
      receipt: 'receipt#1',
      plan_id: planId,
      customer_notify: 1,
      total_count: 6,
      quantity: 1,
      payment_capture: 1, // Auto capture payment
      image: '/assets/img/favicon.png', // Logo URL
      prefill: {
        name: 'Your Name',
        email: 'your.email@example.com',
        contact: '1234567890',
      },
      start_at: Math.floor(Date.now() / 1000),
      // notify_url: 'your_notify_url', // Optional: Replace with your webhook endpoint to receive notifications
      notes: {
        key: apiKey,
        subscription_type: 'monthly',
      },
      handler: async function (res) {
        console.log(res);
        // Handle payment success
        // You can call an API to update payment status or perform any other action
        // await handleSubscriptionCreation(res); // Pass customer ID to create subscription
        // console.log("CUST ID ::" + res.customer_id);
  
        // await createSubscription(res)
      },
    };

    const config = {
      headers: {
        Authorization: authString,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(url, requestData, config);
    console.log(response.data);
    // Handle the subscription response here
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      console.error(error.response.data.error.description);
    } else {
      console.log(error)
      console.error('An error occurred while creating subscription.');
    }
  }
};

export const getOrdersByStatus = async (token, page, restaurantId, status) => {
  const url = `${dev}/order-payments?page=${page}&status=${status}&restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getProductDetails = async (token, order_id) => {
  const url = `${dev}/order-items-x/?order_id=${order_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getPaymentDetails = async (token, order_id) => {
  const url = `${dev}/order/${order_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const createDeliveryOrder = async (token, data) => {
  const url = `${dev}/create-delhivery-order/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const createCancelOrder = async (token, data) => {
  const url = `${dev}/cancel_order/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const markProductUnavailable = async (token, order_item_id, data) => {
  const url = `${dev}/order-item-x/${order_item_id}/`;
  console.log(data)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const trackOrderDetails = async (token, waybill) => {
  const url = `${dev}/order_tracking/?waybill=${waybill}`;
  console.log(token, waybill);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ------------------------------ Category Tab ---------------------------------

export const getMasterCategory = async (token, restaurantId) => {
  const url = `${dev}/get-master-category/${restaurantId}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateCategoryData = async (token, data) => {
  const url = `${dev}/get-master-category/${data.master_category_id}/`;

  console.log(data, "this is the data");

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append(
        "image_url",
        `https://storage.googleapis.com/restaurant60-dev-media/${data[key].name}`
      );
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteCategoryData = async (token, master_category_id) => {
  const url = `${dev}/get-master-category/${master_category_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addCategoryData = async (token, data) => {
  const url = `${dev}/get-master-category/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append(
        "image_url",
        `https://storage.googleapis.com/restaurant60-dev-media/${data[key].name}`
      );
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addOpenHours = async (token, data) => {
  const url = `${dev}/open-hours/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ------------------------------ Sub Category Tab ---------------------------------

export const getSubCategory = async (token, restaurantId) => {
  const url = `${dev}/v2/category/?restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateSubCategorySequence = async (token, types, data) => {
  const url = `${dev}/v2/sequence/`;

  const body = {
    types: types,
    data: data,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  // console.log(url);

  try {
    const response = await axios.put(url, body, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateSubCategoryData = async (token, data) => {
  const url = `${dev}/v2/category/${data.category_id}/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append(
        "category_url",
        `https://storage.googleapis.com/restaurant60-dev-media/${data[key].name}`
      );
    } else if (data[key] === null) {
      // Append other data
    }
     else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  console.log(data)

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addSubCategoryData = async (token, data) => {
  const url = `${dev}/v2/category/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append(
        "category_url",
        `https://storage.googleapis.com/restaurant60-dev-media/${data[key].name}`
      );
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteSubCategoryData = async (token, category_id) => {
  const url = `${dev}/v2/category/${category_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ----------------------------- Products Tab --------------------------------------------

export const getAllProducts = async (token, page, restaurantId) => {
  const url = `${dev}/v2/catalog/?page=${page}&restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addNewProduct = async (token, data) => {
  const url = `${dev}/v2/catalog/`;

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append(
        "product_url",
        `https://storage.googleapis.com/restaurant60-dev-media/${data[key].name}`
      );
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateProductData = async (token, data) => {
  const url = `${dev}/v2/catalog/${data.product_id}/`;
  console.log(data);

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && data[key] !== null && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
      formData.append(
        "product_url",
        `https://storage.googleapis.com/restaurant60-dev-media/${data[key].name}`
      );
    } else if ((key === "image" && data[key] === null) || data[key] === null) {
      // Append other data
    } else {
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getProductsByColorSize = async (token, product_id) => {
  const url = `${dev}/get-product-size-color/?type=All&product_id=${product_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateProductSizeColor = async (token, data) => {
  const url = `${dev}/get-product-size-color/${data.color_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.patch(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addProductSizeColor = async (token, data) => {
  const url = `${dev}/get-product-size-color/`;

  console.log(token, data);

  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (key === "image" && Array.isArray(data[key]) && data[key].length > 0) {
      data[key].forEach((file, index) => {
        if (file instanceof File) {
          formData.append(key, file, file.name);
          formData.append(
            "image_url",
            `https://storage.googleapis.com/restaurant60-dev-media/${file.name}`
          );
        }
      });
    } else {
      // Append other data
      formData.append(key, data[key]);
    }
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, formData, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getProductSpecification = async (token, product_id) => {
  const url = `${dev}/product-specification/?product_id=${product_id}&type=Specification`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getSpecificationLabel = async (token, industry_type) => {
  const url = `${dev}/get-overview-labels/?industry_type=${industry_type}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addProductSpecification = async (token, data) => {
  const url = `${dev}/product-specification/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteProductSpecification = async (token, specification_id) => {
  const url = `${dev}/product-specification/${specification_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// -------------------------------------------------------------------------------------

export const deleteProductData = async (token, category_id) => {
  const url = `${dev}/v2/category/${category_id}/`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateProductSequence = async (token, types, data) => {
  const url = `${dev}/v2/sequence/`;

  const body = {
    types: types,
    data: data,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  // console.log(url);

  try {
    const response = await axios.put(url, body, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ---------------------------- Coupons Tab ------------------------------------------------

export const getAllCoupons = async (token, restaurantId) => {
  const url = `${dev}/coupon/?restaurant_id=${restaurantId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateSingleCoupon = async (token, data) => {
  const url = `${dev}/coupon/${data.coupon_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addSingleCoupon = async (token, data) => {
  const url = `${dev}/coupon/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const deleteSingleCoupon = async (token, coupon_id) => {
  const url = `${dev}/coupon/${coupon_id}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

// ---------------------------- Setup Tab -----------------------------------------------------
